// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import "./DogCard.css";
import DogIcon from "../../assets/svg/DogIcon";
import CalendarIcon from "../../assets/svg/CalendarIcon";
import WeightIcon from "../../assets/svg/WeightIcon";
import Health from "../../assets/svg/Health";
import HealthFilled from "../../assets/svg/HealthFilled";
import ArrowForward from "../../assets/svg/ArrowForward";
import { addFavorite, removeFavorite } from "../../service/actions";
import LoginModal from "../../components/LoginModal";
import { datadogLogs } from "@datadog/browser-logs";
import { DOGCARD_IMAGE_DESKTOP_SIZE, DOGCARD_IMAGE_MOBILE_SIZE } from '../../utils';
import { useLocation } from "react-router-dom";
import LocationIconBadge from "../../assets/svg/LocationIconBadge";
import Badge from '../Badge';

const DogCard: React.FC<{
  allDogs?: boolean;
  img?: String;
  name: String;
  age?: Number;
  breed?: String;
  weight?: Number;
  location?: string;
  id?: any;
  type?: any;
  setFavoriteParent?: any;
  slug?: string;
  application?: boolean;
  applicationStatus?: string;
  applicationId?: string;
}> = ({
  allDogs,
  img,
  name,
  age,
  breed,
  weight,
  id,
  setFavoriteParent,
  slug,
  application,
  applicationStatus,
  applicationId,
}) => {
  const [user, setUser] = useState();
  const [applicationStatusLinkMap, setApplicationLinkMap] = useState({
    INCOMPLETE: "Finish",
    REQUESTED: "Waiting",
    APPROVED: "Make payment",
    DENIED: "See other dogs",
    FINISHED: "Thank you",
  });
  const [isFavorite, setIsFavorite] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  const imageSize = window.innerWidth > 768 ? DOGCARD_IMAGE_DESKTOP_SIZE : DOGCARD_IMAGE_MOBILE_SIZE;

  const textRef = useRef<HTMLSpanElement>(null);

  const location = useLocation();

  const params = location.search;


  const applicationStatusMap = {
    INCOMPLETE: "Application incomplete. Finish now.",
    REQUESTED: "Waiting on PetPair's approval.",
    APPROVED: "Application approved — submit payment now!",
    DENIED: "We could not approve your application.",
    FINISHED: "Adoption finished.",
  };

  const applicationStatusColorMap = {
    INCOMPLETE: "yellow",
    REQUESTED: "green",
    APPROVED: "green",
    DENIED: "red",
    FINISHED: "green",
  };

  const applicationStatusButtonTextMap = {
    INCOMPLETE: "Finish",
    REQUESTED: "See dog",
    APPROVED: "Make payment",
    DENIED: "See other dogs",
    FINISHED: "Thank you",
  };

  let dogs: any = user?.favorite_dogs || [];

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.offsetWidth < textRef.current.scrollWidth
      );
    }
  }, []);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  useEffect(() => {
    try {
      let cachedUser = JSON.parse(localStorage.getItem("user"));

      setApplicationLinkMap({
        INCOMPLETE: `/adopt/create/${cachedUser.id}/${id}/?adoptionId=${applicationId}`,
        REQUESTED: `/dog/${slug}`,
        APPROVED: `/agreement/${applicationId}`,
        DENIED: `/discovery`,
        FINISHED: `/discovery`,
      });
      setUser(cachedUser);
    } catch {
      console.log("Cache not available");
    }

    console.log(id, applicationStatus);
  }, [applicationId, applicationStatus, id, slug]);

  const handleFavorite = (event: any, type) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      setShowModal(true);
      return false;
    }
    const favorite = async () => {
      if (type === "add") {
        datadogLogs.logger.info(`Add to favorites clicked. Dog: ${name}`, {
          user,
          url: window.location.href,
        });
        await addFavorite(user.id, id);
      } else {
        datadogLogs.logger.info(`Remove from favorites clicked. Dog: ${name}`, {
          user,
          url: window.location.href,
        });
        await removeFavorite(user.id, id);
      }
    };
    favorite().then(() => {
      setUser((prevState: any) => {
        if (type === "add") {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...prevState,
              favorite_dogs: [...prevState.favorite_dogs, id],
            })
          );
          return {
            ...prevState,
            favorite_dogs: [...prevState.favorite_dogs, id],
          };
        } else {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...prevState,
              favorite_dogs: prevState.favorite_dogs.filter(
                (dog) => dog !== id
              ),
            })
          );
          return {
            ...prevState,
            favorite_dogs: prevState.favorite_dogs.filter((dog) => dog !== id),
          };
        }
      });
      setIsFavorite(true);
      setTimeout(() => {
        setIsFavorite(false);
      }, 300);

      setTimeout(() => {
        if (setFavoriteParent) {
          setFavoriteParent((prevState) =>
            prevState.filter((dog) => dog.id !== id)
          );
        }
      }, 1000);
    });
  };

  return (
    <div className={`dogcard ${allDogs && "dogcard-mobile-stretch"}`} onClick={() => {
      datadogLogs.logger.info(`Dog card clicked. Dog: ${name}`, {
        user,
        url: window.location.href,
      });
    }}>
      <a
        href={
          application
            ? applicationStatusLinkMap[applicationStatus]
            : "/dog/" + slug + params
        }
        rel="noopener noreferrer"
        className="dogcard-link"
      >
        <div className="dogcard-image-wrapper">
          {!application && (
            <div
              className={`favorite-icon ${
                isFavorite ? "favorite-animate" : ""
              }`}
            >
              {user && dogs.includes(id) ? (
                <HealthFilled
                  onClick={(e: any) => handleFavorite(e, "remove")}
                />
              ) : (
                <Health onClick={(e: any) => handleFavorite(e, "add")} />
              )}
            </div>
          )}
          {application && (
            <div
              className={`dogcard-application-container ${applicationStatusColorMap[applicationStatus]}`}
            >
              <div className="dogcard-application-status">
                <span className="application-status-text">
                  {applicationStatusMap[applicationStatus]}
                </span>
              </div>
            </div>
          )}
          <img
            src={img ? `https://cdn.petpair.co/${imageSize}/${img}` : ""}
            className={`dogcard-image ${
              allDogs && "dogcard-image-mobile-stretch"
            }`}
            alt="Dog"
          />
          <div className='dogcard-badge-container'>
            <Badge Icon={LocationIconBadge} text={'Seguin, TX'} />
          </div>
        </div>
        <div className="dogcard-info">
          <div className="dogcard-name-heart">
            <span className="dogcard-name">{name}</span>
          </div>
          <div className="dogcard-info-container">
            <CalendarIcon />
            <span className="dogcard-info-age">
              {age > 1 ? `${age} years old` : `${age} year old`}
            </span>
          </div>
          <div className="dogcard-info-container">
            <DogIcon />
            <span
              className={`dogcard-info-text${
                isTextOverflowing ? " dogcard-info-overflow" : ""
              }`}
              ref={textRef}
            >
              {" "}
              {truncateText(breed || "", 16)}
            </span>
          </div>
          <div className="dogcard-info-container weight">
            <WeightIcon className="weight-icon" />
            <span className="dogcard-info-text">{weight + " "}lbs.</span>
          </div>
        </div>
        {!application && (
          <div className="dogcard-cta">
            <div>
              <span className="dogcard-cta-text">Adopt me</span>
            </div>
            <div className="dogcard-cta-icon">
              <ArrowForward />
            </div>
          </div>
        )}

        {application && (
          <div className="dogcard-cta">
            <div>
              <span className="dogcard-cta-text">
                {applicationStatusButtonTextMap[applicationStatus]}
              </span>
            </div>
            <div className="dogcard-cta-icon">
              <ArrowForward />
            </div>
          </div>
        )}

        <LoginModal
          showModal={showModal}
          handler={setShowModal}
          createAccount
        />
      </a>
    </div>
  );
};

export default DogCard;

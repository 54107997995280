import React from "react";
import "./home.css";
import Button from "../../components/Button";
import illustration from "../../assets/images/hero.png";
import ArrowDown from "../../assets/svg/ArrowDown";
import { useTranslation } from "react-i18next";

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <div className="home-section1">
      <div className="section1-main-content">
        <div className="section1-left">
          <h1 className="section1-title">{t("home.heading1")}</h1>
          <br />
          <br />
          <span className="section1-title2">{t("home.description1")}</span>
          <div className="section1-buttons">
            <Button
              title={t("home.primaryButton1")}
              location="/discovery"
              filled
            />
            <Button
              title={t("home.secondaryButton1")}
              location="/fosters"
            />
          </div>
        </div>
        <div className="section1-right">
          <img alt='PetPair' src={illustration} className="section1-img" />
        </div>
      </div>
      <div className="section1-scroll-message">
        <div className="arrow-bounce">
          <ArrowDown />
        </div>
        <span className="section1-scroll-text">{t("home.scrollText")}</span>
      </div>
    </div>
  );
};

export default Section1;

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import InputMask from "react-input-mask";
import { makePayment, getAdoption } from "../../service/actions";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import { datadogLogs } from "@datadog/browser-logs";
import "./checkout.css";
import PaymentTiles from "../../components/PaymentTile";
import creditCardType from "credit-card-type";
import { Divider } from "antd";
import { showToast } from "../../utils";
import { useParamsNavigate } from '../../utils';

const Checkout = (props) => {
  const [creditCardNumber, setCreditCardNumber] = useState<any>("");
  const [creditCardBrand, setCreditCardBrand] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [center, setCenter] = useState<any>();
  const radius = 5000;
  const [adoption, setAdoption] = useState();
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { adoptionId } = useParams();

  const navigate = useParamsNavigate();

  const handleCreditCardChange = (e) => {
    const creditCardNumber = e.target.value;
    setCreditCardNumber(creditCardNumber);

    const unmaskedNumber = creditCardNumber
      .replaceAll("_", "")
      .replaceAll(" ", "");

    // Use the credit-card-type library to detect the brand from the credit card number
    const cardTypeInfo = creditCardType(creditCardNumber.replaceAll(" ", ""));
    if (cardTypeInfo.length > 0 && unmaskedNumber.length > 3) {
      const brand = cardTypeInfo[0].type;
      console.log(brand);
      setCreditCardBrand(brand);
    } else {
      setCreditCardBrand(""); // If no brand detected, set it as an empty string
    }
  };

  const handleConfirmPayment = async () => {
    if (creditCardNumber && cvc && expiry && postalCode) {
      setLoading(true);
      const amount = adoption.deliveryFee + adoption.dog.adoptionFee; // Replace with the desired payment amount (in cents)
      const dogName = adoption.dog.name; // Replace with the desired dog name
      const cardNumber = creditCardNumber.replaceAll(" ", ""); // Remove spaces from card number
      const expiryParts = expiry.split("/");
      const expMonth = expiryParts[0].trim();
      const expYear = expiryParts[1].trim();
      const adopterName = adoption.adopter.name;

      const cardNumberRegex = /^[0-9]{15,16}$/;

      if (!cardNumberRegex.test(cardNumber)) {
        showToast("Invalid card number.");
        setLoading(false);
        return;
      }

      const currentYear = new Date().getFullYear().toString().slice(-2);
      const currentMonth = new Date().getMonth() + 1;

      if (
        isNaN(expMonth) ||
        isNaN(expYear) ||
        expYear < currentYear ||
        (expYear === currentYear && expMonth < currentMonth)
      ) {
        showToast(
          "Invalid expiry date. Please provide a valid future date in MM/YY format."
        );
        setLoading(false);
        return;
      }

      try {
        const paymentResult = await makePayment(
          parseInt(amount) * 100,
          dogName,
          cardNumber,
          cvc,
          { month: expMonth, year: expYear },
          adoptionId,
          adopterName
        );
        console.log(paymentResult);
        if (paymentResult.data.success) {
          setIsDone(true);
          showToast("Payment was successful");
          datadogLogs.logger.info(
            `Adoption payment successful. AdoptionID: ${adoptionId}`,
            { payment: paymentResult.data, url: window.location.href }
          );
          navigate(`/checkout/success/${adoption.id}`);
        } else {
          setLoading(false);
          showToast("Payment Failed");
        }
      } catch (err) {
        setLoading(false);
        showToast("Error occurred during payment: " + err.message);
      }
    } else {
      showToast("Please fill all the information before confirming.");
    }
  };

  const handleExpiryChange = (e) => {
    setExpiry(e.target.value);
  };

  const handleCvcChange = (e) => {
    if (e.target.value.length < 5) setCvc(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    if (e.target.value.length < 6) setPostalCode(e.target.value);
  };

  useEffect(() => {
    datadogLogs.logger.info(`Checkout page loaded. AdoptionID: ${adoptionId}`, {
      url: window.location.href,
    });
  }, [adoptionId]);

  useEffect(() => {
    console.log(adoption);
    if (adoption) {
      const geocodeURL = `https://nominatim.openstreetmap.org/search?format=jsonv2&q=${adoption.dog.zipCode}&countrycodes=us`;

      fetch(geocodeURL)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            const usLocation = data.filter(
              (place) =>
                place.display_name.includes("United States") ||
                place.display_name.includes("Estados Unidos")
            );
            console.log(usLocation);
            if (usLocation.length > 0 && usLocation[0]) {
              const { lat, lon } = usLocation[0];
              setCenter([parseFloat(lat), parseFloat(lon)]);
            } else {
              console.log("Location not found for United States.");
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [adoption]);

  useEffect(() => {
    console.log(center);
  }, [center]);

  useEffect(() => {
    const MAX_RETRIES = 5;
    let retryCount = 0;

    const adoption = async () => {
      try {
        const attemptAdoption = async () => {
          const result = await getAdoption(adoptionId, "");
          return result;
        };

        const retry = async () => {
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Retrying (${retryCount}/${MAX_RETRIES})...`);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay for 1 second before retrying
            return adoption();
          } else {
            console.log("Max retries reached. Unable to fetch adoption data.");
          }
        };

        const adoptionData = await attemptAdoption();
        console.log(adoptionData);

        if (adoptionData) {
          if (
            adoptionData.status === "APPROVED" &&
            adoptionData.agreementSigned
          ) {
            setAdoption(adoptionData);
          } else {
            // navigate("/profile?tab=3");
          }
        } else {
          await retry(); // Retry if adoptionData is falsy
        }
      } catch (err) {
        console.log(err);
        await retry(); // Retry on error
      }
    };

    adoption();
  }, [adoptionId]);

  if (!adoption) {
    return (
      <div className="agreement-loading">
        <Spinner isDone={false} />
      </div>
    );
  }

  return (
    <div className="checkout">
      <div className="checkout-left">
        <div className="checkout-left-header">
          <div className="steps-container"></div>
          <span className="checkout-left-title">
            Payment for {adoption.dog.name}
          </span>
          <br />
          <br />
          <PaymentTiles />
          <br />
          <br />
          <div className="checkout-form-container">
            <InputMask
              mask="9999 9999 9999 9999"
              value={creditCardNumber}
              onChange={handleCreditCardChange}
            >
              {(inputProps) => (
                <Input
                  label="Card number"
                  {...inputProps}
                  placeholder="1234 1234 1234 1234"
                  creditCard={true}
                  brand={creditCardBrand}
                />
              )}
            </InputMask>
            <br />
          </div>
          <div className="checkout-card-details">
            <InputMask
              mask="99/99"
              value={expiry}
              onChange={handleExpiryChange}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  label="Expiry"
                  placeholder="MM/YY"
                  value={expiry}
                  onChange={handleExpiryChange}
                />
              )}
            </InputMask>
            <Input
              label="CVC"
              placeholder="CVC"
              value={cvc}
              onChange={handleCvcChange}
            />
            <Input
              label="Postal code"
              placeholder="90210"
              value={postalCode}
              onChange={handlePostalCodeChange}
            />
          </div>
          <br />
          <br />
          <div className="checkout-payment-breakdown">
            <div className="checkout-price-entry">
              <span className="product-name">Adoption Fee</span>
              <span className="product-price">{`$${adoption.dog.adoptionFee}`}</span>
            </div>
            {adoption.deliveryFee && (
              <div className="checkout-price-entry">
                <span className="product-name">Delivery</span>
                <span className="product-price">{`$${adoption.deliveryFee}`}</span>
              </div>
            )}
          </div>
          <Divider />
          <div className="checkout-price-entry">
            <span className="product-name">Total</span>
            <span className="product-price">{`$${
              adoption.deliveryFee + adoption.dog.adoptionFee
            }`}</span>
          </div>
          <br />
          <br />
          <div className="checkout-button-wrapper">
            {loading ? (
              <Spinner isDone={isDone} />
            ) : (
              <div className="checkout-button">
                <Button
                  title="Confirm payment"
                  filled
                  onClick={handleConfirmPayment}
                />
              </div>
            )}
            <div className="checkout-button">
              <Button title="Back" onClick={() => navigate("/profile?tab=3")} />
            </div>
          </div>
        </div>
      </div>
      <div className="checkout-right">
        {center ? ( // <-- Check if 'center' is available
          <MapContainer
            center={center as any}
            zoom={12}
            minZoom={5}
            maxZoom={16}
            className="dogprofile-map-checkout"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={center as any} radius={radius} color="#53B62D" />
          </MapContainer>
        ) : (
          <div className="map-loader">
            <Spinner />
          </div> // <-- You can show a loading message while 'center' is null
        )}
      </div>
    </div>
  );
};

export default Checkout;

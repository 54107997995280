// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Bubble from "../../components/Bubble";
import Steps from "../../components/Steps";
import illustration from "../../assets/images/onboarding.png";
import ArrowForward from "../../assets/svg/ArrowForward";
import { usePlacesWidget } from "react-google-autocomplete";
import InputMask from "react-input-mask";
import {
  getDogDimensions,
  savePreference,
  updateUser,
} from "../../service/actions";
import { age, weight } from "../../utils";
import "./onboarding.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';

const Onboarding = (props) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState<Number>(2);
  const [name, setName] = useState<String>("");
  const [phone, setPhone] = useState<String>("");
  const [location, setLocation] = useState<String>("");
  const [traits, setTraits] = useState<any>([]);
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [selectedTraitsIds, setSelectedTraitsIds] = useState([]);
  const [errorObject, setErrorObject] = useState<any>({
    name: false,
    phone: false,
    location: false,
  });
  const { ref: locationRef } = usePlacesWidget({
    apiKey:`AIzaSyDMfA1xyYe0k6Ajf9uqkYNyjB3n813mzd0`,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
    options: {
      types: 'locality'
    }
  });

  const routerLocation = useLocation();
  const navigate = useParamsNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleBubbleClick = (type, value) => {
    if (type === "age") {
      if (selectedAge.includes(value))
        setSelectedAge((prevState) => prevState.filter((e) => e !== value));
      else setSelectedAge((prevState) => [...prevState, value]);
    }
    if (type === "traits") {
      let name = value.name;
      let id = value.id;
      if (selectedTraits.includes(name)) {
        setSelectedTraits((prevState) => prevState.filter((e) => e !== name));
        setSelectedTraitsIds((prevState) => prevState.filter((e) => e !== id));
      } else {
        setSelectedTraits((prevState) => [...prevState, name]);
        setSelectedTraitsIds((prevState) => [...prevState, id]);
      }
    }
    if (type === "weight") {
      if (selectedWeight.includes(value))
        setSelectedWeight((prevState) => prevState.filter((e) => e !== value));
      else {
        setSelectedWeight((prevState) => [...prevState, value]);
      }
    }
  };

  const handleLocationChange = (e, suggestion) => {
    setLocation(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleUpdateUser = async () => {
    if (!phone || !location || !name) {
      setErrorObject({ phone: !phone, location: !location, name: !name });
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, name, phone, address: location })
    );

    setErrorObject({ phone: false, location: false });

    try {
      const result = await updateUser(
        routerLocation.state.email,
        phone,
        name,
        location,
        routerLocation.state.token
      );
      if (result.status === 200) {
        setSelectedStep(3);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    datadogLogs.logger.info("Onboarding page loaded", {
      user,
      url: window.location.href,
    });
  }, []);

  const handleUserPreference = async () => {
    let result = await savePreference(
      routerLocation.state.email,
      selectedAge,
      selectedTraits,
      selectedWeight
    );

    if (result.status === 200) {
      const traitsParam = selectedTraitsIds.join(",");
      const ageParam = selectedAge.join(",");
      const weightParam = selectedWeight.join(",");
      navigate(
        `/discovery?traits=${traitsParam}&age=${ageParam}&weight=${weightParam}`
      );
    }
  };

  useEffect(() => {
    const dogDimensions = async () => {
      const dimensions = await getDogDimensions();
      setTraits(dimensions.traits);
    };
    dogDimensions();
  }, [selectedStep]);

  return (
    <div className="onboarding">
      <div className="left">
        <div className="onboarding-left-header">
          <div className="steps-container">
            <Steps current={selectedStep} setCurrent={setSelectedStep} />
          </div>
          <span className="onboarding-left-title">
            {selectedStep === 2
              ? t("user.profileDetailsHeader")
              : t("user.interestTitle")}
          </span>
          <p className="onboarding-left-description">
            {selectedStep === 2 ? t("user.profileDetailsDescription") : ""}
          </p>
          <br />
          {selectedStep === 2 ? (
            <div className="onboarding-form-container">
              <Input
                label={t("user.nameLabel")}
                value={name}
                placeholder={t("user.name")}
                onChange={handleNameChange}
                required
                error={errorObject.name}
              />
              <br />
              <InputMask
                mask="(999) 999-9999"
                value={phone}
                onChange={handlePhoneChange}
              >
                {(inputProps) => (
                  <Input
                    label={t("user.phoneLabel")}
                    {...inputProps}
                    placeholder="(440) 555-1234"
                    error={errorObject.phone}
                    required
                  />
                )}
              </InputMask>
              <br />
              <Input
                value={location}
                setInputValue={handleLocationChange}
                onChange={handleLocationChange}
                label={t("user.locationLabel")}
                placeholder={t("user.location")}
                required
                error={errorObject.location}
                ref={locationRef}
              />
              <br />
              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className="onboarding-form-container-bubbles">
              <span className="onboarding-lower-title">Age</span>
              <div className="onboarding-bubbles">
                {age.map((age: any) => (
                  <Bubble
                    title={age}
                    selected={selectedAge.includes(age)}
                    onClick={() => handleBubbleClick("age", age)}
                  />
                ))}
              </div>
              <br />
              <br />
              <span className="onboarding-lower-title">Size</span>
              <div className="onboarding-bubbles">
                {weight.map((weight: any) => (
                  <Bubble
                    title={weight}
                    selected={selectedWeight.includes(weight)}
                    onClick={() => handleBubbleClick("weight", weight)}
                  />
                ))}
              </div>
              <br />
              <br />
              <span className="onboarding-lower-title">Traits</span>
              <div className="onboarding-bubbles">
                {traits.map((element, index) => (
                  <Bubble
                    title={element.name}
                    index={element.name + index}
                    selected={selectedTraits.includes(element.name)}
                    onClick={() =>
                      handleBubbleClick("traits", {
                        name: element.name,
                        id: element.id,
                      })
                    }
                  />
                ))}
              </div>
              <br />
              <br />
            </div>
          )}
        </div>
        <div className="onboarding-button-wrapper">
          <div className="onboarding-button">
            <Button
              title={t("user.next")}
              filled
              onClick={() =>
                selectedStep === 2 ? handleUpdateUser() : handleUserPreference()
              }
            />
          </div>
          <div className="onboarding-skip-container">
            <div className="onboarding-skip-text">
              <span onClick={() => navigate("/discovery")}>
                {t("user.showMeDoggos")}
              </span>
            </div>
            <div className="onboarding-skip-icon">
              <ArrowForward />
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="onboarding-right-header">
          <span className="onboarding-right-title">
            {t("user.onboardingRightTitle")}
          </span>
          <p className="onboarding-right-description">
            {t("user.onboardingRightDescription")}
          </p>
        </div>
        <div className="onboarding-illustration-container">
          <img alt='PetPair' src={illustration} className="onboarding-illustration" />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
} from "react-leaflet";
import { useParams } from "react-router-dom";
import { showToast } from "../../utils";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./dog_profile.css";
import Navbar from "../../components/Navbar";
import PhotoCarousel from "../../components/PhotoCarousel";
import Button from "../../components/Button";
import { Divider } from "antd";
import Tag from "../../components/Tag";
import {
  addFavorite,
  getDogBySlug,
  getDogs,
  removeFavorite,
} from "../../service/actions";
import BackArrow from "../../assets/svg/BackArrow";
import SmallHeart from "../../assets/svg/SmallHeart";
import ShareSmallIcon from "../../assets/svg/ShareSmallIcon";
import Footer from "../../components/Footer";
import { formatDescription } from "../../utils";
import LoginModal from "../../components/LoginModal";
import DogCarousel from "../../components/DogCarousel";
import DogCard from "../../components/DogCard";
import MobileShareIcon from "../../assets/svg/MobileShareIcon";
import HeartFixedCta from "../../assets/svg/HeartFixedCta";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { Helmet } from 'react-helmet-async';
import { useParamsNavigate } from '../../utils';

// Fix Leaflet icon issue
delete (L.Icon.Default.prototype as any)._getIconUrl;

const DogProfile: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [dog, setDog] = useState<any>();
  const [statusAdoption, setStatusAdoption] = useState("NOT_APPLIED");
  const [adoptionId, setAdoptionId] = useState("");
  const [moreDogs, setMoreDogs] = useState([]);
  const [center, setCenter] = useState<any>();
  const radius = 2000;
  const [applyToAdoptClicked] = useState(false);

  if (
    (document as any).getElementsByClassName("leaflet-control-attribution")[0]
  )
    (document as any).getElementsByClassName(
      "leaflet-control-attribution"
    )[0].style.display = "none";

  const { slug } = useParams();

  const navigate = useParamsNavigate();

  const [user, setUser] = useState();

  const handleFavorite = (event: any) => {
    event.stopPropagation();

    if (!user) {
      setShowModal(true);
      return false;
    }

    const favorite = async () => {
      datadogLogs.logger.info(`Add to favorites clicked. Dog: ${dog.name}`, {
        dog,
        user,
        url: window.location.href,
      });
      await addFavorite((user as any).id, dog.id);
    };
    const remove = async () => {
      datadogLogs.logger.info(`Remove from favorites clicked. Dog: ${dog.name}`, {
        dog,
        user,
        url: window.location.href,
      });
      await removeFavorite((user as any).id, dog.id);
    };
    if ((user as any).favorite_dogs.includes(dog.id)) {
      setUser((prevState: any) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...prevState,
            favorite_dogs: prevState.favorite_dogs.filter(
              (element: any) => element !== dog.id
            ),
          })
        );
        return {
          ...prevState,
          favorite_dogs: prevState.favorite_dogs.filter(
            (element: any) => element !== dog.id
          ),
        };
      });
      remove();
      showToast("Removed from favorites");
    } else {
      setUser((prevState: any) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...prevState,
            favorite_dogs: [...prevState.favorite_dogs, dog.id],
          })
        );
        return {
          ...prevState,
          favorite_dogs: [...prevState.favorite_dogs, dog.id],
        };
      });

      favorite();
      showToast("Added to favorites");
    }
  };

  const handleShare = (event: any) => {
    const currentPageURL = window.location.href;
    const message = `Look at the dog I found on PetPair. Here's the link: ${currentPageURL}`;

    navigator.clipboard.writeText(message).then(() => {
      datadogLogs.logger.info(`URL copied to clipboard using the share button. Dog: ${dog.name}`, {
        dog,
        url: window.location.href,
      });
      showToast('Copied to clipboard');
    }).catch((error) => {
      datadogLogs.logger.info(`Error copying the URL to clipboard using the share button. Dog: ${dog.name}`, {
        dog,
        error,
        url: window.location.href,
      });
      showToast('Error copying text to clipboard.');
    });
  };

  useEffect(() => {
    const dogBySlug = async () => {
      const request: any = await getDogBySlug(slug);
      if (request.data.name) setDog(request.data);
      else navigate("/discovery");
    };

    const dogsMore = async () => {
      const request: any = await getDogs("", "", "", "age", [], []);
      setMoreDogs(request.dogs);
    };
    try {
      let user = JSON.parse(localStorage.getItem("user") as string);
      console.log(user);

      setUser(user);
    } catch {
      console.log("No data to parse.");
    }

    dogBySlug();
    dogsMore();
  }, [slug, navigate]);

  useEffect(() => {
    if (dog && user) {
      (window as any).fbq("track", `${dog.name}'s profile visit`);
      // document.title = `${dog.name}, ${dog.breed.name}${
      //   dog.address && " in " + dog.address
      // } | Available to Adopt on PetPair!`;
      let adoptionStatus: any, adoptions;
      adoptions = (user as any).adoptionsAsAdopter.filter(
        (adoption: any) => adoption.dogId === dog.id
      );
      if (adoptions.length > 0) {
        adoptionStatus = adoptions[0].status;
        setStatusAdoption(adoptionStatus);
        setAdoptionId(adoptions[0].id);
      }
    }
  }, [dog, user]);

  useEffect(() => {
    if (dog) {
      const geocodeURL = `https://nominatim.openstreetmap.org/search?format=jsonv2&q=${dog.zipCode}&countrycodes=us`;
      datadogLogs.logger.info(`Dog profile page loaded. Dog: ${dog.name}`, {
        dog,
        url: window.location.href,
      });

      fetch(geocodeURL)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            const usLocation = data.filter((place: any) =>
              place.display_name.includes("United States")
            );

            const { lat, lon } = usLocation[0];
            setCenter([parseFloat(lat), parseFloat(lon)]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dog]);

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const footerRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as per your needs
    };

    const handleIntersection = (entries: any) => {
      const headerEntry = entries[0];
      setIsHeaderVisible(headerEntry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        // eslint-disable-next-line
        observer.unobserve(headerRef.current);
      }
    };
  }, [dog]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as per your needs
    };

    const handleIntersection = (entries: any) => {
      const footerEntry = entries[0];
      setIsFooterVisible(footerEntry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        // eslint-disable-next-line
        observer.unobserve(footerRef.current);
      }
    };
  }, [dog]);

  function formatAge(age: any) {
    const years = Math.floor(age);
    const months = Math.round((age - years) * 12);

    let formattedAge = "";

    if (years > 0) {
      formattedAge += `${years} ${years > 1 ? t("dogProfile.ageTextPlural") : t("dogProfile.ageText")}`;
    }

    if (months > 0) {
      if (years > 0) {
        formattedAge += ` ${t("dogProfile.complementConnector")} `;
      }
      formattedAge += `${months} ${months > 1 ? t("dogProfile.monthTextPlural") : t("dogProfile.monthText")}`;
    }

    return formattedAge;
  }

  if (dog) {
    return (
      <>
        <Helmet>
          <title>{`${dog.name}, ${dog.breed.name}${dog.address ? " in " + dog.address : ""} | Available to Adopt on PetPair!`}</title>
          <meta name="description" content={dog.description} />
        </Helmet>

        <Navbar />
        <div className="dog-profile-wrapper">
          <div
            className="dog-profile-back-container"
            onClick={() => {
              datadogLogs.logger.info(`Back button clicked at the dog profile. Dog: ${dog.name}`, {
                dog,
              });
              navigate("/discovery");
            }}
          >
            <div className="back-icon">
              <BackArrow />
            </div>
            <span className="dog-profile-back">
              {t("dogProfile.backToAll")}
            </span>
          </div>
          <div className="dog-profile-root">
            <div className="dog-profile-left">
              <div className="dog-profile-photo-wrapper">
                <PhotoCarousel photos={dog.photos} videos={dog.videos} />
                <div className="dog-buttons">
                  <Button
                    title={t("dogProfile.adoptButton")}
                    filled
                    dogSlug={dog.slug}
                    onClick={() => {
                      if (!user) {
                        navigate(`/application/${dog.id}`);
                      } else if (statusAdoption === "NOT_APPLIED")
                        navigate(`/application/${dog.id}`);
                      else if (
                        statusAdoption === "REQUESTED" ||
                        statusAdoption === "DENIED" ||
                        statusAdoption === "FINISHED"
                      )
                        showToast(
                          "You already have an application for this dog"
                        );
                      else if (statusAdoption === "INCOMPLETE")
                        navigate(
                          `/adopt/create/${(user as any).id}/${dog.id
                          }/?adoptionId=${adoptionId}`
                        );
                    }}
                  />
                  <div className="dog-profile-sep" />
                  {/* <Button title='Chat with his foster' /> */}
                </div>
              </div>
            </div>
            <div className="dog-profile-right">
              <div className="dog-profile-header" ref={headerRef}>
                <span className="dog-profile-name">{dog.name}</span>
                <div className="options">
                  <div className="options-wrapper-share" onClick={handleShare}>
                    <div className="dog-profile-icon">
                      <ShareSmallIcon />
                    </div>
                    <span>{t("dogProfile.share")}</span>
                  </div>
                  <div className="options-wrapper">
                    <div className="dog-profile-icon">
                      <SmallHeart />
                    </div>
                    <span
                      className="dog-profile-favorite"
                      onClick={handleFavorite}
                    >
                      {user && (user as any).favorite_dogs.includes(dog.id)
                        ? t("dogProfile.removeFavorite")
                        : t("dogProfile.favorite")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="dog-profile-info-container">
                <div className="dog-info">
                  <span className="title">{t("dogProfile.age")}</span>
                  <span className="data">
                    {formatAge(dog.age)}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.gender")}</span>
                  <span className="data">
                    {dog.gender === "FEMALE"
                      ? t("dogProfile.genderFemale")
                      : t("dogProfile.genderMale")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.weight")}</span>
                  <span className="data">
                    {dog.weight} {t("dogProfile.weightUnit")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.breed")}</span>
                  <span className="data">{dog.breed.name}</span>
                </div>
              </div>
              <div className="traits-tags">
                {dog.personality.map((trait: any) => (
                  <Tag
                    title={trait.name}
                    description={formatDescription(
                      trait.description,
                      dog.name,
                      dog.gender
                    )}
                  />
                ))}
              </div>
              <div className="dog-profile-description">
                <span className="dog-profile-heavy-text">
                  {formatDescription(
                    t("dogProfile.moreAboutHeader"),
                    dog.name,
                    dog.gender
                  )}
                </span>
                <span className="dog-profile-description-text">
                  {dog.description}
                </span>
              </div>
              <div className="dog-profile-info-container">
                <div className="dog-info">
                  <span className="title">{t("dogProfile.fixed")}</span>
                  <span className="data">
                    {dog.fixed ? t("dogProfile.yes") : t("dogProfile.no")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.training")}</span>
                  {dog.trainedFor.length > 0 ? (
                    dog.trainedFor.map((training: any) => (
                      <span className="data">{training.name}</span>
                    ))
                  ) : (
                    <span className="data">
                      Needs some help learning the basics
                    </span>
                  )}
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.commands")}</span>
                  {dog.commands.length > 0 ? (
                    dog.commands.map((command: any) => (
                      <span className="data">{command.name}</span>
                    ))
                  ) : (
                    <span className="data">None</span>
                  )}
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.sleepStyle")}</span>
                  <span className="data">
                    {dog.sleepStyle.name || "Unknown"}
                  </span>
                </div>
              </div>
              <Divider />
              <span className="adoption-title">
                {t("dogProfile.adoptionInfo")}
              </span>
              <div className="lower-section">
                <div className="dog-profile-adoption">
                  <div className="adoption-info-low">
                    <span className="title">{t("dogProfile.fee")}</span>
                    <span className="price">{`$${dog.adoptionFee}`}</span>
                    <Divider />
                    <span className="title">
                      {t("dogProfile.includedTitle")}
                    </span>
                    <Tag
                      title={t("dogProfile.food")}
                      description={formatDescription(
                        t("dogProfile.foodTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={t("dogProfile.goodies")}
                      description={formatDescription(
                        t("dogProfile.goodiesTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={t("dogProfile.bed")}
                      description={formatDescription(
                        t("dogProfile.bedTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={formatDescription(
                        t("dogProfile.insurance"),
                        dog.name,
                        dog.gender
                      )}
                      description={formatDescription(
                        t("dogProfile.insuranceTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                  </div>
                </div>
                <div className="other-info">
                  <div className="other-info-low">
                    <span className="title">
                      {formatDescription(
                        t("dogProfile.location"),
                        dog.name,
                        dog.gender
                      )}
                    </span>
                    {center && (
                      <MapContainer
                        center={center as any}
                        zoom={12}
                        minZoom={5}
                        maxZoom={16}
                        className="dogprofile-map"
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Circle
                          center={center as any}
                          radius={radius}
                          color="#53B62D"
                        />
                      </MapContainer>
                    )}

                    <Divider />
                    <span className="title2">Transportation</span>
                    <span className="heavy-value">Pickup & home drop-off</span>
                    <span className="title2">Foster</span>
                    <a
                      className="heavy-green-value"
                      href="/fosters" onClick={() => {
                        datadogLogs.logger.info(`"${t("dogProfile.fosterCta")}" CTA clicked.`, {
                          user,
                          url: window.location.href,
                        });
                      }}
                    >
                      {formatDescription(
                        t("dogProfile.fosterCta"),
                        dog.name,
                        dog.gender
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="dog-profile-dogs">
            <div className="more-dogs-title-wrapper">
              <span className="title">
                {formatDescription(
                  t("dogProfile.moreLikeHeader"),
                  dog.name,
                  dog.gender
                )}
              </span>
            </div>
            <DogCarousel>
              {moreDogs.map((dog: any, index: number) => (
                <DogCard
                  slug={dog.slug}
                  key={dog.slug + dog.name + dog.index}
                  name={dog.name}
                  age={dog.age}
                  weight={dog.weight}
                  breed={dog.breed.name}
                  img={(dog.photos[0] || '')}
                  location={"/dog/" + dog.slug}
                  id={dog.id}
                />
              ))}
            </DogCarousel>
          </div>
          <LoginModal
            showModal={showModal}
            handler={setShowModal}
            createAccount
            applyToAdopt={applyToAdoptClicked}
            dogId={dog.id}
          />
          <div className={isFooterVisible ? "mobile-cta-bottom" : "mobile-cta"}>
            <div
              className={
                !isHeaderVisible ? "mobile-cta-left" : "mobile-cta-left-none"
              }
            >
              <span className="mobile-cta-title">{dog.name}</span>
              <div className="mobile-options-container">
                <MobileShareIcon
                  className="mobile-share"
                  onClick={handleShare}
                />
                <HeartFixedCta
                  onClick={handleFavorite}
                  className={
                    user && (user as any).favorite_dogs.includes(dog.id)
                      ? "mobile-heart-filled"
                      : "mobile-heart"
                  }
                />
              </div>
            </div>
            <div
              className={
                !isHeaderVisible ? "mobile-cta-right" : "mobile-cta-right-full"
              }
            >
              <Button
                title={"Apply to adopt"}
                filled
                onClick={() => {
                  if (!user) {
                    navigate(`/application/${dog.id}`);
                  } else if (statusAdoption === "NOT_APPLIED")
                    navigate(`/application/${dog.id}`);
                  else if (
                    statusAdoption === "REQUESTED" ||
                    statusAdoption === "DENIED" ||
                    statusAdoption === "FINISHED"
                  )
                    showToast("You already have an application for this dog");
                  else if (statusAdoption === "INCOMPLETE")
                    navigate(
                      `/adopt/create/${(user as any).id}/${dog.id
                      }/?adoptionId=${adoptionId}`
                    );
                  console.log(statusAdoption);
                }}
              />
            </div>
          </div>
        </div>
        <div ref={footerRef}>
          <Footer />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};




export default DogProfile;

import React from "react";

const BreadcrumbArrow = () => (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.19305 0.473185C0.933047 0.733185 0.933047 1.15319 1.19305 1.41319L3.77971 3.99985L1.19305 6.58652C0.933047 6.84652 0.933047 7.26652 1.19305 7.52652C1.45305 7.78652 1.87305 7.78652 2.13305 7.52652L5.19305 4.46652C5.45305 4.20652 5.45305 3.78652 5.19305 3.52652L2.13305 0.466518C1.87971 0.213185 1.45305 0.213185 1.19305 0.473185Z"
      fill="#DCDCDC"
    />
  </svg>
);

export default BreadcrumbArrow;

import React from "react";
import "./Spinner.css";

interface SpinnerProps {
  isDone: boolean;
  page?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ isDone, page }) => {
  return (
    <div
      className={`circle-loader ${isDone && "load-complete"} ${
        page && "page-layout"
      } `}
    >
      <div
        className={`${isDone ? "checkmark-complete" : "checkmark-none"} draw`}
      ></div>
    </div>
  );
};

export default Spinner;

import React from "react";
import CreditCard from "../../assets/svg/CreditCard";
import "./payment-tile.css";

const PaymentTiles: React.FC = () => {
  return (
    <div className="payment-tile">
      <CreditCard />
      <span className="payment-name">Card</span>
    </div>
  );
};

export default PaymentTiles;

import LogoFooter from "../../assets/svg/LogoFooter";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { useParamsNavigate } from '../../utils';
import { useLocation } from "react-router-dom";

const Footer = (props: any) => {
  const navigate = useParamsNavigate();
  const { t } = useTranslation();
  
  const location = useLocation();

  const params = location.search;

  return (
    <div className="footer" {...props}>
      <div className="footer-bottom">
        <div className="temp-logo">
          <LogoFooter
            className="footer-logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="footer-menu">
          <ul>
            <li>
              <a href={`/about${params}`} onClick={() => {
                      datadogLogs.logger.info(`About link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>{t("navigation.about")}</a>
            </li>
            <li>
              <a href={`/shelters${params}`} onClick={() => {
                      datadogLogs.logger.info(`Shelters link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>{t("navigation.shelters")}</a>
            </li>
            <li>
              <a href={`/fosters${params}`} onClick={() => {
                      datadogLogs.logger.info(`Fosters link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>{t("navigation.fosters")}</a>
            </li>
            <li>
              <a href={`/discovery${params}`} onClick={() => {
                      datadogLogs.logger.info(`Adopters link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>{t("navigation.adopters")}</a>
            </li>
            <li>
              <a href={`/blog${params}`} onClick={() => {
                      datadogLogs.logger.info(`Blog link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>{t("navigation.news")}</a>
            </li>
          </ul>
        </div>
        <div className="footer-copyright-disclaimer">
          <span className="footer-copyright-text">
            {t("navigation.copyright")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

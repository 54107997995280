import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export const age = ["Puppy", "Young", "Adult", "Old"];

export const weight = [
  "Small (<20lbs)",
  "Medium (20-50lbs)",
  "Large (50+ lbs)",
];

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const showToast = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4500, // Duration in milliseconds, set to 0 to disable auto-close
    className: "custom-toast",
  });
};

export const formatDescription = (
  description: string,
  dogName: string,
  gender: string
) => {
  const objectPronoun = gender === "MALE" ? "him" : "her";
  const possessivePronoun = gender === "MALE" ? "his" : "her";
  const toBe = gender === "MALE" ? "he is" : "she is";
  const personalPronoun = gender === "MALE" ? "he" : "she";

  return (description || "")
    .replaceAll("{{name}}", dogName)
    .replaceAll("{{to-be}}", toBe)
    .replaceAll("{{possessive-pronoun}}", possessivePronoun)
    .replaceAll("{{object-pronoun}}", objectPronoun)
    .replaceAll("{{personal-pronoun}}", personalPronoun);
};

export const formatDate = (inputDate: string) => {
  // Step 1: Parse the input date string into a JavaScript Date object
  const dateObj = new Date(inputDate);

  // Step 2: Extract the necessary components
  const year = dateObj.getFullYear();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();

  // Step 3: Format the date into the desired output format
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

export const useParamsNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithPreservedParams = (to: any) => {
    // Append the current query parameters to the new URL
    const targetURL = `${to}${location.search}`;

    // Navigate to the target URL
    navigate(targetURL);
  };

  return navigateWithPreservedParams;
};



export const DOGCARD_IMAGE_DESKTOP_SIZE='306x310';
export const DOGCARD_IMAGE_MOBILE_SIZE='205x192';
export const PROFILE_PHOTO_DESKTOP_IMAGE_SIZE = '550x504';
export const PROFILE_PHOTO_MOBILE_IMAGE_SIZE = '350x349';
// @ts-nocheck

import React, { useState } from "react";
import "./fb-button.css";
import facebookLogo from "../../assets/images/fb-logo.png";
import { datadogLogs } from "@datadog/browser-logs";
import {
  LoginSocialFacebook
} from "reactjs-social-login";
import {
  loginWithFacebook,
  checkEmailExists,
  registerSocial,
} from "../../service/actions";
import Spinner from "../Spinner";
import { showToast } from "../../utils";
import { useParamsNavigate } from '../../utils';

const appId = "719852622893058";

const FacebookButton = ({ dogId }) => {
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const navigate = useParamsNavigate();

  const loginStart = () => {
    datadogLogs.logger.info(
      `Facebook Button: Login attempt.`
    );
  }

  const onLogin = async (res) => {
    const { email, name } = res.data;
    setLoading(true);

    try {
      const emailExists = await checkEmailExists(email);

      if (emailExists) {
        const loginResult = await loginWithFacebook(email);
        if (loginResult.token) {
          localStorage.setItem("user", JSON.stringify(loginResult?.user));
          localStorage.setItem("token", JSON.stringify(loginResult?.token));
          datadogLogs.logger.info(
            `User logged in with Facebook: UserID: ${loginResult?.user.id}`,
            { user: loginResult?.user, url: window.location.href }
          );
          navigate(`/adopt/create/${loginResult?.user.id}/${dogId}`, {
            state: { email, token: loginResult.token },
          });
        } else {
          showToast("Try again later.");
        }
      } else {
        const registerResult = await registerSocial({
          name,
          email,
          accountProvider: "facebook",
        });
        console.log(registerResult);
        if (registerResult.status === 200) {
          setIsDone(true);
          localStorage.setItem(
            "user",
            JSON.stringify(registerResult?.data.user)
          );
          localStorage.setItem(
            "token",
            JSON.stringify(registerResult?.data.token)
          );
          datadogLogs.logger.info(
            `New account created with Facebook: UserID: ${registerResult?.data.user.id}`,
            { user: registerResult?.data.user, url: window.location.href }
          );
          navigate(`/adopt/create/${registerResult?.data.user.id}/${dogId}`, {
            state: { email, token: registerResult.data.token },
          });
        } else {
          console.log("aaaaaa");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Spinner isDone={isDone} />;

  return (
    <LoginSocialFacebook
      appId={appId}
      fieldsProfile={
        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
      }
      onLoginStart={loginStart}
      //   onLogoutSuccess={onLogoutSuccess}
      //   redirect_uri={REDIRECT_URI}
      onResolve={(res) => {
        onLogin(res);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <div className="fb-button-root">
        <img src={facebookLogo} className="fb-logo" alt="Facebook"/>
        <span className="fb-cta">Continue with Facebook</span>
      </div>
    </LoginSocialFacebook>
  );
};

export default FacebookButton;

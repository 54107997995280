import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ROUTES from "./utils/routes";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./service/i18n";
import { datadogLogs } from "@datadog/browser-logs";
import { HelmetProvider } from 'react-helmet-async';

console.log(
  "Copyright © 2023 PetPair, Inc. All rights reserved. Void where prohibited. Prohibited where void. Void, void, my kingdom for a void."
);

const router = createBrowserRouter(ROUTES);

datadogLogs.init({
  clientToken: "pub54a501db90da86ea0e11bde3b9d0a83d",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: "PetPairWebsite",
});



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// console.error = () => {};

root.render(
  <React.StrictMode>
    <HelmetProvider>
    <RouterProvider router={router} />
    <ToastContainer
      position={window.innerWidth <= 768 ? "top-right" : "bottom-right"}
    />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

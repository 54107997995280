import React, { useState } from "react";
import { useParamsNavigate } from '../../utils';
import "./options-button.css";

interface ButtonProps {
  filled?: boolean;
  small?: boolean;
  location?: string;
  title: any;
  onClick?: () => void;
  options?: any;
}

const Button: React.FC<ButtonProps> = ({
  filled,
  small,
  location,
  title,
  onClick,
  options,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useParamsNavigate();

  const handleButtonClick = () => {
    if (location) {
      if (isExternalURL(location)) {
        window.location.href = location; // Open external URL in the same window
      } else {
        navigate(location); // Navigate to internal URL using react-router-dom
      }
    } else if (onClick) {
      onClick();
    }
  };

  const isExternalURL = (url: string) => {
    return /^(https?:\/\/)|(mailto:)|(tel:)/.test(url);
  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
  };

  return (
    <div
      className={`option-button ${
        filled ? "option-button-filled" : "option-button-normal"
      } ${small && "option-button-small"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleButtonClick}
    >
      {title}
      {options && showOptions && (
        <div className="options-balloon">
          {options.map((option: any) => (
            <div
              key={option.value}
              className="option-item"
              onClick={option.value}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Button;

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { getAdoption } from "../../service/actions";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import { datadogLogs } from "@datadog/browser-logs";
import "./checkout-success.css";
import Confetti from "react-confetti";
import { useParamsNavigate } from '../../utils';

const CheckoutSuccess = (props) => {
  const [center, setCenter] = useState<any>();
  const radius = 5000;
  const [adoption, setAdoption] = useState();
  const [showConfetti, setShowConfetti] = useState(true);

  const { adoptionId } = useParams();

  const navigate = useParamsNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (adoption) {
      const geocodeURL = `https://nominatim.openstreetmap.org/search?format=jsonv2&q=${adoption.dog.zipCode}&countrycodes=us`;

      datadogLogs.logger.info(
        `Checkout success page loaded. AdoptionID: ${adoptionId}`,
        { adoption, url: window.location.href }
      );

      fetch(geocodeURL)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            const usLocation = data.filter(
              (place) =>
                place.display_name.includes("United States") ||
                place.display_name.includes("Estados Unidos")
            );

            if (usLocation.length > 0 && usLocation[0]) {
              const { lat, lon } = usLocation[0];
              setCenter([parseFloat(lat), parseFloat(lon)]);
            } else {
              console.log("Location not found for United States.");
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [adoption, adoptionId]);

  useEffect(() => {
    console.log(center);
  }, [center]);

  useEffect(() => {
    const adoption = async () => {
      try {
        const adoption = await getAdoption(adoptionId, "");
        if (adoption) {
          if (adoption.status === "FINISHED") setAdoption(adoption);
          else navigate("/profile?tab=3");
        } else navigate("/profile?tab=3");
      } catch (err) {
        navigate("/profile?tab=3");
      }
    };

    adoption();
  }, [adoptionId, navigate]);

  if (!adoption) {
    return (
      <div className="agreement-loading">
        <Spinner isDone={false} />
      </div>
    );
  }

  return (
    <div className="checkout">
      <div className="checkout-success-left">
        {showConfetti && <Confetti />}
        <span className="checkout-success-title">
          Congrats, {adoption.adopter.name}! {adoption.dog.name} is yours!
        </span>
        <span className="checkout-success-description">
          We look forward to discussing the next steps to bring{" "}
          {adoption.dog.name} to your home. {adoption.dog.name} can't wait to
          meet you!
        </span>
        <div className="delivery-information">
          {adoption.pickupAddress && (
            <div className="checkout-success-summary-entry">
              <span className="checkout-success-summary-label">
                Address of the shelter
              </span>
              <span className="checkout-success-summary-value">
                {adoption.pickupAddress}
              </span>
            </div>
          )}
          {adoption.transportContact && (
            <div className="checkout-success-summary-entry">
              <span className="checkout-success-summary-label">
                Contact info
              </span>
              <span className="checkout-success-summary-value">
                {adoption.transportContact}
              </span>
            </div>
          )}
          {adoption.transportInformation && (
            <div className="checkout-success-summary-entry">
              <span className="checkout-success-summary-label">
                Pickup information
              </span>
              <span className="checkout-success-summary-value">
                {adoption.transportInformation}
              </span>
            </div>
          )}
        </div>
        <div className="checkout-success-buttons">
          <Button
            title="Ask a question"
            filled
            location={`mailto:adopt@petpair.co?subject=${adoption.dog.name}%20|%20Question%20about%20${adoption.dog.name}%20adoption`}
          />
          <Button title="Back to my profile" location="/profile?tab=3" />
        </div>
      </div>
      <div className="checkout-right">
        {center ? (
          <MapContainer
            center={center as any}
            zoom={12}
            minZoom={5}
            maxZoom={16}
            className="dogprofile-map-checkout"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={center as any} radius={radius} color="#53B62D" />
          </MapContainer>
        ) : (
          <div className="map-loader">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSuccess;

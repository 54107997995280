import React, { useEffect, useState } from 'react';
import './news.css';
import Navbar from '../../components/Navbar';
import Button from '../../components/Button';
import news from '../../assets/images/news.png';
import { Divider } from 'antd';
import Footer from '../../components/Footer';
import { getBlogPosts, subscribeToNewsletter } from '../../service/actions';
import { datadogLogs } from "@datadog/browser-logs";
import { Helmet } from 'react-helmet-async';
import Input from '../../components/Input';
import { showToast } from '../../utils/index';
import { useParamsNavigate } from '../../utils';


const News = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [email, setEmail] = useState("");

    const navigate = useParamsNavigate();

    useEffect(() => {
        datadogLogs.logger.info(`Blog page loaded`);
        const fetchBlogPosts = async () => {
            try {
                const result = await getBlogPosts();
                if (result && result.data) {
                    setBlogPosts(result.data.posts.filter((post: any) => post.slug !== 'terms-of-use' && post.slug !== 'privacy'));
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogPosts();
    }, []);

    const subscribe = async () => {
        if(!email) {
            showToast('Please enter an email.');
            return;
        }
        try {
            await subscribeToNewsletter(email);
            datadogLogs.logger.info(`New newsletter subscribed. Email: ${email}`);
            showToast(`Subscribed!`);
        } catch (err) {
            showToast(`Error trying to subscribe. Try again later.`);
            console.error(err);
        }
    };

    const formatDate = (inputDate: any) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options as any);
    }

    return (
        <>
            <Helmet>
                <title>{`PetPair | Blog`}</title>
                <meta name="description" content={`Learn more about fostering, adopting, raising, playing with, transporting, and loving dogs.`} />
            </Helmet>
            <div className='news-root'>
                <Navbar />
                <div className='news-header'>
                    <div className='news-left-header'>
                        <h1 className='news-h1'>PetPair is here to help you be the best pet parent you can be.</h1>
                        <div className='news-button-container'>
                            <div>
                            <Input placeholder="Email" label="" value={email} onChange={(e: any) => { setEmail(e.target.value)}}/>
                            <p className="adoption-call-disclaimer">
                                Stay up to date with our expert pet parenting tips!
                            </p>
                            </div>
                            <div className='news-button'>
                            <Button title='Subscribe' onClick={subscribe} filled />
                            </div>
                        </div>
                    </div>
                    <img src={news} alt='PetPair' className='news-img' />
                </div>
                <Divider className='news-divider' />
                <div className='news-article-list'>
                    <h2 className='news-article-list-title'>Our latest tips, tricks, and treats...</h2>
                    <br />
                    <br />
                    <div className='news-articles'>
                        {blogPosts.map((post: any) => (
                            <div className='news-article-listing' onClick={() => navigate('/blog/post/' + post.slug)}>
                                <div className='news-article-listing-img-container'>
                                    <img src={post.feature_image} className='news-article-listing-img' alt='petpair' />
                                </div>
                                <div className='news-article-listing-text-container'>
                                    <span className='news-article-listing-title'>{post.title}</span>
                                    <span className='news-article-listing-date'>{formatDate(post.published_at)}</span>
                                    <span className='news-article-listing-description'>{post.excerpt.split('.')[0]}</span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default News;


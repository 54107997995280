// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import PhoneButton from "../../components/PhoneButton";
import AppDogCard from "../../components/AppDogCard";
import InputMask from "react-input-mask";
import Confetti from "react-confetti";
import {
  updateAdoption,
  updateUser,
  getDogById,
  requestAdoption,
  getAdoption,
  getUserByEmail,
  registerSocial,
  updatePassword,
} from "../../service/actions";
import { showToast } from "../../utils";
import "./application.css";
import { useTranslation } from "react-i18next";
import { usePlacesWidget } from "react-google-autocomplete";
import { useParamsNavigate } from '../../utils';

const AdoptionApp = (props) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showName, setShowName] = useState(true);
  const [showPhone, setShowPhone] = useState(true);
  const [showLocation, setShowLocation] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [location, setLocation] = useState("");
  const [pets, setPets] = useState([]);
  const [over18, setOver18] = useState("");
  const [homeType, setHomeType] = useState("");
  const [children, setChildren] = useState("");
  const [homeOwnership, setHomeOwnership] = useState("");
  const [forbiddenHouseholdBreeds, setForbiddenHouseholdBreeds] = useState("");
  const [motivation, setMotivation] = useState("");
  const [contactType, setContactType] = useState("");
  const [estimatedSpend, setEstimatedSpend] = useState("");
  const [previousDog, setPreviousDog] = useState("");
  const [dealbreakers, setDealbreakers] = useState([]);
  const [token, setToken] = useState("");
  const [adoptionId, setAdoptionId] = useState("");
  const [user, setUser] = useState();
  const [dog, setDog] = useState("");
  const [current, setCurrent] = useState(0);
  const [max, setMax] = useState(5);
  const [showConfetti, setShowConfetti] = useState(false);
  const [adopterId, setAdopterId] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [password, setPassword] = useState("");
  const [emailFromParam, setEmailFromParam] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { ref: locationRef } = usePlacesWidget({
    apiKey:`AIzaSyDMfA1xyYe0k6Ajf9uqkYNyjB3n813mzd0`,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
    options: {
      types: 'locality'
    }
  });

  const step1 = [
    email,
    name,
    phone,
    location,
    motivation
  ];
  const step2 = [password];

  const routerLocation = useLocation();
  const navigate = useParamsNavigate();
  const { dogId } = useParams();

  const windowWidth = window.innerWidth;

  const handleMobileButtonWrapperClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useState(() => {
    if(emailFromParam)
      handleEmailRequest();
  }, [emailFromParam])


  const handleEmailRequest = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailValue = emailFromParam ? emailFromParam : email;

    setEmailFromParam('');

    if (!emailPattern.test(emailValue)) {
      showToast("Please enter a valid email");
      return;
    }

    try {
      const { data } = await getUserByEmail(emailValue);
      const { user, token } = data;

      setShowEmail(false);
      setAdopterId(user.id);
      setToken(token);
      setUser(user);

      if(!adoptionId && user) {
        const adoptionRequest = {
          name,
          phone,
          location,
          pets: pets.join(", "),
          over18,
          homeType,
          children,
          homeOwnership,
          forbiddenHouseholdBreeds,
          motivation,
          contactType,
          estimatedSpend,
          previousDog,
          adopterId: user.id,
          dogId,
          dealbreakers,
          status: "INCOMPLETE",
        };
          const adoptRequest = await requestAdoption(adoptionRequest, token);
              setAdoptionId(adoptRequest.data.adoption.id);
              datadogLogs.logger.info(
                `Adoption successfully requested using short app. AdoptionID: ${adoptRequest.data.adoption.id}`,
                {
                  dog: adoptRequest.data.adoption.dog,
                  user: adoptRequest.data.adoption.user,
                  url: window.location.href,
                }
              );
              queryParams.set("adoptionId", adoptRequest?.data.adoption.id);
              const newSearch = queryParams.toString();
              navigate({ search: `?${newSearch}` });
        }

      if (user.name) {setName(user.name); setShowName(false)};
      if (user.address) {setLocation(user.address); setShowLocation(false)};
      if (user.phone) {setPhone(user.phone); setShowPhone(false)};
    } catch (err) {
      if (err.response.status === 404) {
        try {
          const { data } = await registerSocial({ email: emailValue });
          console.log('successfully got the user', data);

          const { user, token } = data;

          if (!adoptionId && user) {
            const adoptionRequest = {
              name,
              phone,
              location,
              pets: pets.join(", "),
              over18,
              homeType,
              children,
              homeOwnership,
              forbiddenHouseholdBreeds,
              motivation,
              contactType,
              estimatedSpend,
              previousDog,
              adopterId: user.id,
              dogId,
              dealbreakers,
              status: "INCOMPLETE",
            };
            
          const adoptRequest = await requestAdoption(adoptionRequest, token);
          setAdoptionId(adoptRequest.data.adoption.id);
          datadogLogs.logger.info(
            `Adoption successfully requested using short app. AdoptionID: ${adoptRequest.data.adoption.id}`,
            {
              dog: adoptRequest.data.adoption.dog,
              user: adoptRequest.data.adoption.user,
              url: window.location.href,
            }
          );
          queryParams.set("adoptionId", adoptRequest?.data.adoption.id);
          const newSearch = queryParams.toString();
          navigate({ search: `?${newSearch}` });
          }

          setShowEmail(false);
          setAdopterId(user.id);
          setToken(token);
          setIsNewAccount(true);
          setUser(user);

          if (user.name) setShowName(false);
          if (user.address) setShowLocation(false);
          if (user.phone) setShowPhone(false);
        } catch (err) {
          console.log(err);
        }
      } else {
        showToast(
          "We could not process your request at this time, please try again later."
        );
      }
    }
  };

  useEffect(() => {
    let filledCount;
    if (selectedStep === 1) {
      filledCount = step1.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    } else if (selectedStep === 2) {
      filledCount = step2.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    } else if (selectedStep === 3) {
      filledCount = step3.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    }

    setCurrent(filledCount);
    // eslint-disable-next-line
  }, [
    email,
    password,
    name,
    phone,
    location,
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    forbiddenHouseholdBreeds,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers,
    selectedStep
  ]);

  useEffect(() => {

    datadogLogs.logger.info(
      `Short application page loaded. DogID: ${dogId}`,
      { url: window.location.href }
    );

    if (!dogId) {
      navigate("/");
      console.log();
    } else {
      getDogById(dogId).then((res) => {
        setDog(res.data);
        console.log(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const emailParam = queryParams.get('email');
    const nameParam = queryParams.get('name');
    const phoneParam = queryParams.get('phone');

    if(emailParam) {
      setEmailFromParam(emailParam);
      setEmail(emailParam);
      handleEmailRequest();
    }

    if(nameParam) setName(nameParam);
    if(phoneParam) setPhone(phoneParam);

// eslint-disable-next-line
  }, [routerLocation.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const adoptionIdParam = queryParams.get("adoptionId");
    if (token && adopterId) {
      if (!adoptionIdParam) {
        const adoptionRequest = {
          name,
          phone,
          location,
          pets: pets.join(", "),
          over18,
          homeType,
          children,
          homeOwnership,
          forbiddenHouseholdBreeds,
          motivation,
          contactType,
          estimatedSpend,
          previousDog,
          adopterId,
          dogId,
          dealbreakers,
          status: "INCOMPLETE",
        };
        requestAdoption(adoptionRequest, token)
          .then((res) => {
            setAdoptionId(res?.data.adoption.id);
            datadogLogs.logger.info(
              `Adoption successfully requested using short app. AdoptionID: ${res?.data.adoption.id}`,
              {
                dog: res?.data.adoption.dog,
                user: res?.data.adoption.user,
                url: window.location.href,
              }
            );
            queryParams.set("adoptionId", res?.data.adoption.id);
            const newSearch = queryParams.toString();
            navigate({ search: `?${newSearch}` });
          })
          .catch(() => navigate("/"));
      } else {
        setAdoptionId(adoptionIdParam);
        getAdoption(adoptionIdParam, '')
          .then((adoptionData) => {
            setPets(adoptionData.pets ? adoptionData.pets.split(", ") : []);
            setOver18(adoptionData.over18);
            setHomeType(adoptionData.homeType);
            setChildren(adoptionData.children);
            setHomeOwnership(adoptionData.homeOwnership);
            setForbiddenHouseholdBreeds(adoptionData.forbiddenHouseholdBreeds);
            setMotivation(adoptionData.motivation);
            setContactType(adoptionData.contactType);
            setEstimatedSpend(adoptionData.estimatedSpend);
            setPreviousDog(adoptionData.previousDog);
            setDealbreakers(adoptionData.dealbreakers);
          })
          .catch(() => navigate("/"));
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleNext = (step) => {
    const nextStep = step !== 0 ? step : selectedStep + 1;
    if (
      nextStep === 2 &&
      (!name || !motivation ||
        !/^\d{10}$/.test(phone.replace(/[^0-9]/g, "")) || // Modify this line
        !location)
      //     pets.length === 0 ||
      //     !homeOwnership ||
      //     !over18 ||
      //     !children ||
      //     !homeType ||
      //     !forbiddenHouseholdBreeds)) ||
      // (forbiddenHouseholdBreeds === "yes" &&
      //   (!forbiddenBreeds ||
      //     !/^\d{10}$/.test(landlordNumber.replace(/[^0-9]/g, ""))))
    ) {
      console.log(name, phone, location);
      showToast("You need to fill every information before continuing");
    } 
    // else if (nextStep === 3 && (!motivation || !estimatedSpend)) {
    //   showToast("You need to fill every information before continuing");
    // } 
    else {
      setSelectedStep(nextStep);
      setMax(1);
      setCurrent(0);
    }
  };

  useEffect(() => {
    setMax(selectedStep === 1 ? 5 : selectedStep === 2 ? 1 : 1);
    if (selectedStep === 3) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [selectedStep]);

  const handleDealbreakerChange = (value, isChecked) => {
    if (isChecked) {
      setDealbreakers((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setDealbreakers((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handleAdoptionUpdate = async (isFinished, isLastStep, route) => {
    // Perform validation or any other necessary checks

    // Create an adoption request object
    const adoptionRequest = {
      name,
      phone,
      location,
      pets: pets.join(", "),
      over18,
      homeType,
      children,
      homeOwnership,
      forbiddenHouseholdBreeds,
      motivation,
      contactType,
      estimatedSpend,
      previousDog,
      adopterId,
      dogId,
      dealbreakers,
      status: isFinished ? "REQUESTED" : "INCOMPLETE",
    };

    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateAdoption(adoptionId, adoptionRequest, token);
      if(isFinished) {
        datadogLogs.logger.info(`Application completed using short app. Application ID: ${adoptionId}`);
      }
      console.log("Adoption updated successfully");

      // Handle the result as per your requirement
      if (result.status === 200) {
        
        if(isLastStep){
          try {
            if(isNewAccount){
              await updatePassword('', password, adopterId, token);
              showToast("Password set! Updates to your application will be shown here and by email.")
              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
            else {
              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
          } catch (err) {
            console.log(err);
          }
        
      }
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  useEffect(() => {
    console.log(pets);
  }, [pets]);

  const handleEstimatedSpendChange = (event) => {
    const rawValue = event.target.value.replace(/[^\d.]/g, "");
    const formattedValue = formatValue(rawValue);
    setEstimatedSpend(formattedValue);
  };

  const formatValue = (value) => {
    if (!value) return "";
    const parts = value.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decimalPart = parts[1] ? `.${parts[1]}` : "";
    return `$ ${integerPart}${decimalPart}`;
  };

  const handleUserUpdate = async () => {
    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateUser(email, phone, name, location, token);

      // Handle the result as per your requirement
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data));
        console.log("Adoption updated successfully", result);
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleAdoptionUpdate(false);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (token && (name || phone || location)) handleUserUpdate();
    }, 500);
    return () => clearTimeout(timeout);
    
    // eslint-disable-next-line
  }, [name, phone, location]);

  return (
    <div className="adoption">
      {showConfetti && <Confetti />}
      <div className="short-app-left">
        <div className="adoption-left-header">
          <div className="app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            {selectedStep !== 2 && <Counter max={max} current={current} />}
          </div>
          <div></div>
          {selectedStep === 1 && (
            <div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">{"Your Info"}</span>
                
              </div>
              <p className="adoption-left-description">
                {
                  "Please provide all the details below, make sure that everything you enter is correct."
                }
              </p>
              <br />
              <div className="adoption-form-container">
                <Input
                  label={"Email"}
                  value={email}
                  placeholder={"Enter your email"}
                  onChange={handleEmailChange}
                  required
                  disabled={!showEmail}
                  onBlur={handleEmailRequest}
                />
                <br />
                {showName && (
                  <>
                    <Input
                      label={t("user.nameLabel")}
                      value={name}
                      placeholder={t("user.name")}
                      onChange={handleNameChange}
                      required
                    />
                    <br />
                  </>
                )}
                {showPhone && (
                  <>
                    <InputMask
                      mask="(999) 999-9999"
                      value={phone}
                      onChange={handlePhoneChange}
                    >
                      {(inputProps) => (
                        <Input
                          label={t("user.phoneLabel")}
                          {...inputProps}
                          placeholder="(440) 555-1234"
                          value={phone}
                          onChange={handlePhoneChange}
                          required
                        />
                      )}
                    </InputMask>
                    <p className="adoption-call-disclaimer">
                      We will call you once the application is approved
                    </p>
                    <br />
                  </>
                )}

                {showLocation && (
                  <>
                    <Input
                      value={location}
                      onChange={handleLocationChange}
                      label={t("user.locationLabel")}
                      placeholder={t("user.location")}
                      required
                      ref={locationRef}
                    />

                    <br />
                  </>
                )}

                <Input
                  label={t("Why do you want do adopt?")}
                  value={motivation}
                  placeholder={"Tell us"}
                  onChange={(e) => setMotivation(e.target.value)}
                  required
                  textArea
                />
                <br />
              </div>
            </div>
          )}
          {selectedStep === 2 && (
            <>{isNewAccount ? <div className="adoption-form-scrollable-container">
            <div className="adoption-header">
              <span className="adoption-left-title">
                {"Application submitted! Now, finish creating your account."}
              </span>
            </div>
            <p className="adoption-left-description">
              {
                "Adding a password will let you access your application and communicate with our adoption team 24/7!"
              }
            </p>
            <br />
            
            <>
              <br />
              <div className='adoption-form-container' >
              <Input
                label={"Email"}
                value={email}
                placeholder={"Enter your email"}
                onChange={handleEmailChange}
                required
                onBlur={handleEmailRequest}
                disabled
              />
            <br />

            
            <Input
                label='Password' 
                placeholder='Enter a password'
                type='password'
                onChange={(e) => { setPassword(e.target.value)}}
              />
              </div>
            </>
            
            
          </div> : (<div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">
                  {"Application submitted!"}
                </span>
              </div>
              <p className="adoption-left-description">
                {
                  "Now, all that's left is a quick call with our team. Please confirm the phone number below is correct."
                }
              </p>
              <br />

              <div className="adoption-contact-container">
                <span className="adoption-mid-title">
                  The best number to reach you is:
                </span>
                <PhoneButton
                  phoneNumber={phone}
                  fn={() => setSelectedStep(1)}
                />
                <br />
                <br />
                
              </div>
              
              
            </div>)}</>
          )}
          {selectedStep === 10 && (
            <div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">{"Application"}</span>
                <Counter max={max} current={current} />
              </div>
              <p className="adoption-left-description">
                {"We're assessing 'fit' for that dog, not overall fitness."}
              </p>
              <br />

              <div className="adoption-form-container">
                

                <Input
                  label={t(
                    "How much money (in $) do you anticipate spending per year on food, training, toys, vet bills, etc?"
                  )}
                  placeholder={t("Enter estimated spend")}
                  onChange={handleEstimatedSpendChange}
                  value={estimatedSpend}
                  required
                />

                <br />
                <p className="adoption-mid-title">Additional information</p>
                <p>Have you ever owned a dog before?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="previousDog"
                      value="yes"
                      id="previousDog-yes"
                      checked={previousDog === "yes"}
                      onChange={(e) => setPreviousDog(e.target.value)}
                    />
                    <label htmlFor="previousDog-yes">Yes</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="previousDog"
                      value="no"
                      id="previousDog-no"
                      checked={previousDog === "no"}
                      onChange={(e) => setPreviousDog(e.target.value)}
                    />
                    <label htmlFor="previousDog-no">No</label>
                  </div>
                </div>
                <br />
                <p>Do you have any dealbreakers in terms of adopting a dog?</p>
                <div className="checkbox-grid">
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Excessive medical need")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Excessive medical need",
                          e.target.checked
                        )
                      }
                    />
                    Excessive medical need
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Resource guarding")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Resource guarding",
                          e.target.checked
                        )
                      }
                    />
                    Resource guarding
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Jumpy behavior")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Jumpy behavior",
                          e.target.checked
                        )
                      }
                    />
                    Jumpy behavior
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Excessive barking")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Excessive barking",
                          e.target.checked
                        )
                      }
                    />
                    Excessive barking
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Separation Anxiety")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Separation Anxiety",
                          e.target.checked
                        )
                      }
                    />
                    Separation Anxiety
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes(
                        "History of aggression towards dogs, cats, and/or children"
                      )}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "History of aggression towards dogs, cats, and/or children",
                          e.target.checked
                        )
                      }
                    />
                    History of aggression towards dogs, cats, and/or children
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Leash Puller")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Leash Puller",
                          e.target.checked
                        )
                      }
                    />
                    Leash Puller
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Not house trained")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Not house trained",
                          e.target.checked
                        )
                      }
                    />
                    Not house trained
                  </label>
                  {/* Add more checkboxes and labels as needed */}
                </div>
              </div>
            </div>
          )}
          {windowWidth> 900 ? selectedStep < 2 ? (
            <div className="adoption-button-wrapper">
              <Button
                title={"Back"}
                onClick={() => {
                  if (selectedStep === 1) navigate("/profile?tab=3");
                  else setSelectedStep((previous) => previous - 1);
                }}
              />
              <Button
                title={selectedStep === 1 ? "Send" : t("user.next")}
                filled
                onClick={() => {
                  if (selectedStep === 1) {
                    handleAdoptionUpdate(true, false);
                  }
                  handleNext(0);
                }}
              />
            </div>
          ) : (
            <div className="adoption-button-wrapper">
              <Button
                title={"More dogs"}
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/discovery');
                }}
              />
              <Button
                title={"Complete"}
                filled
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/profile?tab=3');
                }}
              />
            </div>
          ) : 
            <div className="adoption-mobile-button-wrapper" onClick={handleMobileButtonWrapperClick}>
              <div className="adoption-mobile-dog-info">
                <img src={`https://cdn.petpair.co/55x55/${dog && dog.photos[0]}`} className='mobile-bar-dog-photo' alt='PetPair'/>
                <span className='mobile-bar-dog-name'>{dog.name}</span>
              </div>
              <Button
                title={selectedStep === 1 ? "Send" : t("user.next")}
                filled
                onClick={() => {
                  if (selectedStep === 1) {
                    handleAdoptionUpdate(true, false);
                  } else {
                    handleAdoptionUpdate(true, true);
                  }
                  handleNext(0);
                }}
              />
          </div>
        }
        </div>
      </div>

      
        {selectedStep === 2 ? (
          <div className="right-short-adoption-summary">
          <div className="short-adoption-summary">
            {dog && (
              <img alt='PetPair' src={dog.photos[0]} className="adoption-img-summary" />
            )}
            <div className="ribbon">
              <span className="ribbon-text">complete!</span>
            </div>
            <div className="adoption-summary-info">
              <span className="adoption-summary-title">{name ? `${name}'s application for ${dog.name}` : `Application for ${dog.name}`}</span>
            </div>
            <div className="adoption-summary-adopter-info">
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Name</span>
                <span className="adoption-summary-value">{name}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Phone number</span>
                <span className="adoption-summary-value">{phone}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Location</span>
                <span className="adoption-summary-value">{location}</span>
              </div>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Why do you want to adopt?
              </span>
              <span className="adoption-summary-value">{motivation}</span>
            </div>
          </div>
          </div>
        ) : (
          <div className='right-short-adoption'>
            {dog && 
              <AppDogCard slug={dog.slug} address={dog.address} img={dog.photos[0]} name={dog.name} age={dog.age} breed={dog.breed.name} weight={dog.weight} description={dog.description} />
            }
          </div>
        )}

      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="ok" filled title={'Ok'} onClick={handleModalClose}>
            Close
          </Button>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {dog && (
          <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
          />
        )}
      </Modal>
        
    </div>
  );
};

export default AdoptionApp;

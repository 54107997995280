// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import {
  getUploadUrl,
  getAdoption,
  updateAdoption,
} from "../../service/actions";
import CloudCheck from "../../assets/svg/CloudCheck";
import "./agreement.css";
import { showToast } from "../../utils";
import { useParamsNavigate } from '../../utils';

const Agreement = () => {
  const [signature, setSignature] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [isFileOver, setIsFileOver] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [adoption, setAdoption] = useState();
  const [fixDate, setFixDate] = useState("");

  const navigate = useParamsNavigate();
  const { adoptionId } = useParams();

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setIsFileOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFilePreview(file);
    }
  };

  const handleFileSelectChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFilePreview(file);
    }
  };

  const handleFilePreview = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImageUrl(reader.result);
    };
    reader.readAsDataURL(file);

    const fileParts = file.name.split(".");
    const extension = fileParts[fileParts.length - 1];
    const generatedFileName = adoptionId + "." + extension;
    setFileName(generatedFileName);
    setFileExtension(extension);
  };

  const handleSubmit = async () => {
    if (previewImageUrl && signature && confirmation) {
      let blob = dataURLtoBlob(previewImageUrl);
      try {
        const adoptionData = { ...adoption, agreementSigned: true };

        await updateAdoption(adoptionId, adoptionData, "");
        await axios.put(uploadUrl, blob);
        setTimeout(() => {
          datadogLogs.logger.info(
            `Adoption agreement signed! AdoptionID: ${adoptionId}`,
            { adoption: adoptionData, url: window.location.href }
          );
          navigate(`/checkout/${adoptionId}`);
        }, 250);
      } catch (err) {
        console.error("Error uploading photo:", err);
      }
    } else {
      showToast("Please fill all the information.");
    }
  };

  useEffect(() => {
    const uploadImage = async () => {
      if (previewImageUrl && fileName) {
        try {
          const url = await getUploadUrl(fileName, "");
          setUploadUrl(url);
        } catch (err) {
          console.error("Error getting upload URL:", err);
        }
      }
    };

    uploadImage();
  }, [previewImageUrl, fileName]);

  useEffect(() => {

    datadogLogs.logger.info(
      `Agreement page loaded. AdoptionID: ${adoptionId}`,
      { url: window.location.href }
    );

    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + 28);

    const formattedDate = `${
      currentDate.getMonth() + 1
    }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    setFixDate(formattedDate); // Output: "7/20/2023" (assuming today is 6/22/2023)
  }, [adoptionId]);

  useEffect(() => {
    const adoption = async () => {
      try {
        const adoption = await getAdoption(adoptionId, "");
        if (adoption) {
          if (adoption.status === "APPROVED") setAdoption(adoption);
          else navigate("/profile?tab=3");
        } else navigate("/profile?tab=3");
      } catch (err) {
        navigate("/profile?tab=3");
      }
    };

    adoption();
  }, [adoptionId, navigate]);

  const fileInputRef = React.createRef();

  if (!adoption) {
    return (
      <div className="agreement-loading">
        <Spinner isDone={false} />
      </div>
    );
  }

  return (
    <>
      <div
        className="agreement-root"
        onDragOver={(e) => {
          e.preventDefault();
          setIsFileOver(true);
        }}
        onDragLeave={() => setIsFileOver(false)}
        onDragEnter={() => setIsFileOver(true)}
      >
        <Navbar />
        <div className="agreement">
          <div className="agreement-left">
            <span className="agreement-title">PetPair Adoption Agreement</span>
            <p className="agreement-disclaimer">
              This adoption agreement is to be made between PetPair Inc. and
              {" " + adoption.adopter.name} (“Adopter”). This agreement
              transfers ownership of the pet named {adoption.dog.name} from
              PetPair Inc. to Adopter. Adopter agrees to abide by the following
              rules and regulations as per PetPair’s Adoption Agreement:{" "}
            </p>
            <ul className="agreement-list">
              <li>I confirm that I am 18 years of age or older.</li>
              <li>I confirm that I am adopting this animal for myself.</li>
              <li>
                I confirm that I have not been convicted of animal cruelty,
                neglect, or abandonment.
              </li>
              <li>
                I agree to care for my pet in a humane manner and to be a
                responsible animal guardian. I will only use positive and humane
                methods of training and handling.
              </li>
              <li>
                I understand that I am taking on full financial responsibility
                for this pet and will not hold PetPair Inc. responsible for any
                future expenses related to this pet.
              </li>
              <li>
                If the pet I am adopting is not spayed/neutered, I agree to have
                my pet spayed/neutered if required by state law by {fixDate}.
              </li>
              <li>
                I understand that PetPair Inc. cannot make any guarantees
                regarding the pet’s health, behavior, temperament, age, or breed
                and that any known health conditions or behavior issues will be
                disclosed to me prior to adoption.
              </li>
              <li>
                I agree to provide proper food, water, shelter, and veterinary
                care for my pet.
              </li>
              <li>
                I understand that any future medical needs for my pet will be my
                sole responsibility and at my own expense and that PetPair Inc.
                makes no guarantees about the health of adopted pets.
              </li>
              <li>
                I agree to always have a leash, collar, and proper ID tag on my
                pet when outdoors.
              </li>
              <li>
                I agree to abide by all state and local laws regarding pets
                where I live, including requirements for rabies vaccinations,
                pet registration, and local leash/tethering laws.
              </li>
              <li>
                If I can no longer care for my pet, I agree to contact PetPair
                Inc. before rehoming them.
              </li>
              <li>
                I agree to have my pet as a companion pet and not for guarding
                or protection purposes.
              </li>
              <li>
                I agree to pay the adoption fee of $
                {adoption.deliveryFee + adoption.dog.adoptionFee} for my pet.
              </li>
              <li>
                I agree to release PetPair Inc. from any liability for personal
                injury, property damage, legal fees, or any other expense or
                liability incurred by Adopter as a result of this adoption.
              </li>
            </ul>
          </div>
          <div className="agreement-right">
            <div
              className={`agreement-upload-box ${
                isFileOver ? "file-over" : ""
              }`}
              onDrop={handleFileDrop}
              onClick={handleFileSelect}
            >
              {previewImageUrl ? (
                <>
                  {fileExtension === "pdf" ? (
                    <CloudCheck className="cloud-icon" />
                  ) : (
                    <img
                      className={
                        fileExtension === "pdf"
                          ? "agreement-pdf-image"
                          : "agreement-preview-image"
                      }
                      src={previewImageUrl}
                      alt="Preview"
                    />
                  )}
                  <span className="agreement-box-placeholder">
                    {fileExtension === "pdf"
                      ? "PDF file selected. Drag or click to select another file"
                      : "Drag or click to select another file"}
                  </span>
                </>
              ) : (
                <>
                  <div className="agreement-box-title">
                    1. Add photo of license
                  </div>
                  <span className="agreement-box-placeholder">
                    {isFileOver ? "Drop here" : "Accepts .pdf, .jpg, and .png"}
                  </span>
                </>
              )}
              <input
                type="file"
                accept=".pdf,.jpg,.png"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelectChange}
              />
            </div>
            <div className="agreement-signature-input">
              <Input
                placeholder="Type your name"
                label="Your signature"
                value={signature}
                onChange={(e) => {
                  setSignature(e.target.value);
                }}
              />
            </div>
            <div className="agreement-signature-box">
              {signature ? (
                <span className="agreement-box-signature">{signature}</span>
              ) : (
                <span className="agreement-box-placeholder">
                  2. Your signature
                </span>
              )}
            </div>
            <div>
              <div className="app-radio-option">
                <label>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={confirmation}
                    onChange={(e) => setConfirmation((prevState) => !prevState)}
                  />
                  <span className="agreement-confirmation">
                    I confirm and acknowledge the statements above
                  </span>
                </label>
              </div>
            </div>
            <Button filled title="Submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Agreement;

import React from "react";

const StarIcon = () => (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0439 2.10458C13.3512 1.482 13.5048 1.17071 13.7134 1.07125C13.8949 0.984714 14.1058 0.984714 14.2873 1.07125C14.4959 1.17071 14.6495 1.482 14.9568 2.10458L17.8724 8.01121C17.9631 8.19501 18.0085 8.28691 18.0748 8.35826C18.1335 8.42144 18.2039 8.47263 18.2821 8.50899C18.3704 8.55006 18.4718 8.56488 18.6746 8.59453L25.1963 9.54777C25.8831 9.64815 26.2265 9.69834 26.3854 9.86608C26.5236 10.012 26.5887 10.2126 26.5623 10.4119C26.5321 10.6409 26.2835 10.8831 25.7863 11.3673L21.0689 15.9621C20.9219 16.1053 20.8483 16.1769 20.8009 16.2621C20.7589 16.3376 20.7319 16.4204 20.7215 16.5062C20.7098 16.603 20.7272 16.7042 20.7619 16.9065L21.8749 23.3963C21.9923 24.0808 22.051 24.4231 21.9407 24.6262C21.8447 24.8029 21.6741 24.9268 21.4764 24.9635C21.2492 25.0056 20.9418 24.844 20.3271 24.5207L14.4968 21.4546C14.3152 21.3591 14.2243 21.3113 14.1286 21.2925C14.0439 21.2759 13.9568 21.2759 13.872 21.2925C13.7764 21.3113 13.6855 21.3591 13.5039 21.4546L7.67356 24.5207C7.05888 24.844 6.75154 25.0056 6.52429 24.9635C6.32657 24.9268 6.15596 24.8029 6.05998 24.6262C5.94966 24.4231 6.00836 24.0808 6.12576 23.3963L7.23884 16.9065C7.27354 16.7042 7.29089 16.603 7.27915 16.5062C7.26876 16.4204 7.24181 16.3376 7.1998 16.2621C7.15236 16.1769 7.07883 16.1053 6.93177 15.9621L2.2144 11.3673C1.71721 10.8831 1.46861 10.6409 1.43836 10.4119C1.41204 10.2126 1.47706 10.012 1.61533 9.86608C1.77424 9.69834 2.11762 9.64815 2.80438 9.54777L9.32607 8.59453C9.52889 8.56488 9.6303 8.55006 9.71862 8.50899C9.79681 8.47263 9.86721 8.42144 9.92591 8.35826C9.9922 8.28691 10.0376 8.19501 10.1283 8.01121L13.0439 2.10458Z"
      stroke="#53B62D"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default StarIcon;

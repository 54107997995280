// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Counter from "../../components/Counter";
import Steps from "../../components/Steps";
import PhoneButton from "../../components/PhoneButton";
import HeartFixedCta from "../../assets/svg/HeartFixedCta";
import defaultAvatar from "../../assets/images/defaultAvatar.png";
import InputMask from "react-input-mask";
import Confetti from "react-confetti";
import {
  updateAdoption,
  updateUser,
  getDogById,
  requestAdoption,
  getAdoption,
  getUserByEmail,
  registerSocial,
  updatePassword,
} from "../../service/actions";
import { showToast } from "../../utils";
import "./application.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';

const AdoptionApp = (props) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showName, setShowName] = useState(true);
  const [showPhone, setShowPhone] = useState(true);
  const [showLocation, setShowLocation] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [location, setLocation] = useState("");
  const [pets, setPets] = useState([]);
  const [over18, setOver18] = useState("");
  const [homeType, setHomeType] = useState("");
  const [children, setChildren] = useState("");
  const [homeOwnership, setHomeOwnership] = useState("");
  const [forbiddenHouseholdBreeds, setForbiddenHouseholdBreeds] = useState("");
  const [motivation, setMotivation] = useState("");
  const [contactType, setContactType] = useState("");
  const [estimatedSpend, setEstimatedSpend] = useState("");
  const [previousDog, setPreviousDog] = useState("");
  const [dealbreakers, setDealbreakers] = useState([]);
  const [token, setToken] = useState("");
  const [adoptionId, setAdoptionId] = useState("");
  const [user, setUser] = useState();
  const [dog, setDog] = useState("");
  const [landlordNumber, setLandlordNumber] = useState("");
  const [forbiddenBreeds, setForbiddenBreeds] = useState("");
  const [current, setCurrent] = useState(0);
  const [max, setMax] = useState(9);
  const [showConfetti, setShowConfetti] = useState(false);
  const [adopterId, setAdopterId] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [password, setPassword] = useState("");
  const [emailFromParam, setEmailFromParam] = useState();

  const step1 = [
    name,
    phone,
    location,
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    forbiddenHouseholdBreeds,
  ];
  const step2 = [motivation, estimatedSpend, previousDog];
  const step3 = [contactType];

  const routerLocation = useLocation();
  const navigate = useParamsNavigate();
  const { dogId } = useParams();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useState(() => {
    if(emailFromParam)
      handleEmailRequest();
  }, [emailFromParam])


  const handleEmailRequest = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailValue = emailFromParam ? emailFromParam : email;

    setEmailFromParam('');

    if (!emailPattern.test(emailValue)) {
      showToast("Please enter a valid email");
      return;
    }

    try {
      const { data } = await getUserByEmail(emailValue);
      const { user, token } = data;

      setShowEmail(false);
      setAdopterId(user.id);
      setToken(token);
      setUser(user);

      if (user.name) {setName(user.name); setShowName(false)};
      if (user.address) {setLocation(user.address); setShowLocation(false)};
      if (user.phone) {setPhone(user.phone); setShowPhone(false)};
    } catch (err) {
      if (err.response.status === 404) {
        try {
          const { data } = await registerSocial({ email: emailValue });
          console.log('successfully got the user', data);

          const { user, token } = data;

          setShowEmail(false);
          setAdopterId(user.id);
          setToken(token);
          setIsNewAccount(true);
          setUser(user);

          if (user.name) setShowName(false);
          if (user.address) setShowLocation(false);
          if (user.phone) setShowPhone(false);
        } catch (err) {
          console.log(err);
        }
      } else {
        showToast(
          "We could not process your request at this time, please try again later."
        );
      }
    }
  };

  useEffect(() => {
    let filledCount;
    if (selectedStep === 1) {
      filledCount = step1.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    } else if (selectedStep === 2) {
      filledCount = step2.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    } else if (selectedStep === 3) {
      filledCount = step3.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    }

    setCurrent(filledCount);
    // eslint-disable-next-line
  }, [
    name,
    phone,
    location,
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    forbiddenHouseholdBreeds,
    forbiddenBreeds,
    landlordNumber,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers,
    selectedStep
  ]);

  useEffect(() => {

    datadogLogs.logger.info(
      `Adoption application page loaded. DogID: ${dogId}`,
      { url: window.location.href }
    );

    if (!dogId) {
      navigate("/");
    } else {
      getDogById(dogId).then((res) => {
        setDog(res.data);
        console.log(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const emailParam = queryParams.get('email');
    const nameParam = queryParams.get('name');
    const phoneParam = queryParams.get('phone');

    if(emailParam) {
      setEmailFromParam(emailParam);
      setEmail(emailParam);
      handleEmailRequest();
    }

    if(nameParam) setName(nameParam);
    if(phoneParam) setPhone(phoneParam);

// eslint-disable-next-line
  }, [routerLocation.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const adoptionIdParam = queryParams.get("adoptionId");
    if (token && adopterId) {
      if (!adoptionIdParam) {
        const adoptionRequest = {
          name,
          phone,
          location,
          pets: pets.join(", "),
          over18,
          homeType,
          children,
          homeOwnership,
          forbiddenHouseholdBreeds,
          motivation,
          breeds: forbiddenBreeds,
          landlordNumber,
          contactType,
          estimatedSpend,
          previousDog,
          adopterId,
          dogId,
          dealbreakers,
          status: "INCOMPLETE",
        };
        requestAdoption(adoptionRequest, token)
          .then((res) => {
            setAdoptionId(res?.data.adoption.id);
            datadogLogs.logger.info(
              `Adoption successfully requested. AdoptionID: ${res?.data.adoption.id}`,
              {
                dog: res?.data.adoption.dog,
                user: res?.data.adoption.user,
                url: window.location.href,
              }
            );
            queryParams.set("adoptionId", res?.data.adoption.id);
            const newSearch = queryParams.toString();
            navigate({ search: `?${newSearch}` });
          })
          .catch(() => navigate("/"));
      } else {
        setAdoptionId(adoptionIdParam);
        getAdoption(adoptionIdParam, token)
          .then((adoptionData) => {
            setPets(adoptionData.pets ? adoptionData.pets.split(", ") : []);
            setOver18(adoptionData.over18);
            setHomeType(adoptionData.homeType);
            setChildren(adoptionData.children);
            setHomeOwnership(adoptionData.homeOwnership);
            setForbiddenHouseholdBreeds(adoptionData.forbiddenHouseholdBreeds);
            setMotivation(adoptionData.motivation);
            setContactType(adoptionData.contactType);
            setEstimatedSpend(adoptionData.estimatedSpend);
            setPreviousDog(adoptionData.previousDog);
            setDealbreakers(adoptionData.dealbreakers);
          })
          .catch(() => navigate("/"));
      }
    }
    // eslint-disable-next-line
  }, [token, adopterId]);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleNext = (step) => {
    const nextStep = step !== 0 ? step : selectedStep + 1;
    console.log(
      !/^\d{10}$/.test(phone.replace(/[^0-9]/g, "")),
      !/^\d{10}$/.test(landlordNumber.replace(/[^0-9]/g, ""))
    );
    if (
      nextStep === 2 &&
      (!name ||
        !/^\d{10}$/.test(phone.replace(/[^0-9]/g, "")) || // Modify this line
        !location)
      //     pets.length === 0 ||
      //     !homeOwnership ||
      //     !over18 ||
      //     !children ||
      //     !homeType ||
      //     !forbiddenHouseholdBreeds)) ||
      // (forbiddenHouseholdBreeds === "yes" &&
      //   (!forbiddenBreeds ||
      //     !/^\d{10}$/.test(landlordNumber.replace(/[^0-9]/g, ""))))
    ) {
      console.log(name, phone, location);
      showToast("You need to fill every information before continuing");
    } else if (nextStep === 3 && (!motivation || !estimatedSpend)) {
      showToast("You need to fill every information before continuing");
    } else {
      setSelectedStep(nextStep);
      setMax(nextStep === 2 ? 4 : 1);
      setCurrent(0);
    }
  };

  useEffect(() => {
    setMax(selectedStep === 1 ? 9 : selectedStep === 2 ? 3 : 1);
    if (selectedStep === 3) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [selectedStep]);

  const handleDealbreakerChange = (value, isChecked) => {
    if (isChecked) {
      setDealbreakers((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setDealbreakers((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handleAdoptionUpdate = async (isFinished, isLastStep, route) => {
    // Perform validation or any other necessary checks

    // Create an adoption request object
    const adoptionRequest = {
      name,
      phone,
      location,
      pets: pets.join(", "),
      over18,
      homeType,
      children,
      homeOwnership,
      forbiddenHouseholdBreeds,
      breeds: forbiddenBreeds,
      landlordNumber,
      motivation,
      contactType,
      estimatedSpend,
      previousDog,
      adopterId,
      dogId,
      dealbreakers,
      status: isFinished ? "REQUESTED" : "INCOMPLETE",
    };

    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateAdoption(adoptionId, adoptionRequest, token);
      console.log("Adoption updated successfully");

      // Handle the result as per your requirement
      if (result.status === 200) {
        
        if(isLastStep){
          try {
            if(isNewAccount){
              await updatePassword('', password, adopterId, token);
              showToast("Password set! Updates to your application will be shown here and by email.")
              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
            else {
              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
          } catch (err) {
            console.log(err);
          }
        
      }
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setPets((prevPets) => [...prevPets, value]);
    } else {
      setPets((prevPets) => prevPets.filter((pet) => pet !== value));
    }
  };

  useEffect(() => {
    console.log(pets);
  }, [pets]);

  const handleEstimatedSpendChange = (event) => {
    const rawValue = event.target.value.replace(/[^\d.]/g, "");
    const formattedValue = formatValue(rawValue);
    setEstimatedSpend(formattedValue);
  };

  const formatValue = (value) => {
    if (!value) return "";
    const parts = value.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decimalPart = parts[1] ? `.${parts[1]}` : "";
    return `$ ${integerPart}${decimalPart}`;
  };

  const handleUserUpdate = async () => {
    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateUser(email, phone, name, location, token);

      // Handle the result as per your requirement
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data));
        console.log("Adoption updated successfully", result);
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleAdoptionUpdate(false);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    forbiddenHouseholdBreeds,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (token && (name || phone || location)) handleUserUpdate();
    }, 500);
    return () => clearTimeout(timeout);
    
    // eslint-disable-next-line
  }, [name, phone, location]);

  return (
    <div className="adoption">
      {showConfetti && <Confetti />}
      <div className="left">
        <div className="adoption-left-header">
          <div className="steps-container">
            <Steps current={selectedStep} setCurrent={handleNext} application />
          </div>
          <div></div>
          {selectedStep === 1 && (
            <div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">{"Your Info"}</span>
                {selectedStep !== 3 && <Counter max={max} current={current} />}
              </div>
              <p className="adoption-left-description">
                {
                  "Please provide all the details below, make sure that everything you enter is correct."
                }
              </p>
              <br />
              <div className="adoption-form-container">
                <Input
                  label={"Email"}
                  value={email}
                  placeholder={"Enter your email"}
                  onChange={handleEmailChange}
                  required
                  disabled={!showEmail}
                  onBlur={handleEmailRequest}
                />
                <br />
                {showName && (
                  <>
                    <Input
                      label={t("user.nameLabel")}
                      value={name}
                      placeholder={t("user.name")}
                      onChange={handleNameChange}
                      required
                    />
                    <br />
                  </>
                )}
                {showPhone && (
                  <>
                    <InputMask
                      mask="(999) 999-9999"
                      value={phone}
                      onChange={handlePhoneChange}
                    >
                      {(inputProps) => (
                        <Input
                          label={t("user.phoneLabel")}
                          {...inputProps}
                          placeholder="(440) 555-1234"
                          value={phone}
                          onChange={handlePhoneChange}
                          required
                        />
                      )}
                    </InputMask>
                    <p className="adoption-call-disclaimer">
                      We will call you once the application is approved
                    </p>
                    <br />
                  </>
                )}

                {showLocation && (
                  <>
                    <Input
                      value={location}
                      onChange={handleLocationChange}
                      label={t("user.locationLabel")}
                      placeholder={t("user.location")}
                      required
                    />

                    <br />
                  </>
                )}
                <p className="adoption-mid-title">Additional information</p>
                <p>Do you have any pets at home?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      id="cats"
                      value="Cats"
                      checked={pets.includes("Cats")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="cats">Cats</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      id="dogs"
                      value="Dogs"
                      checked={pets.includes("Dogs")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="dogs">Dogs</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      id="no-pets"
                      value="Other"
                      checked={pets.includes("Other")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="no-pets">Other</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      id="no-pets"
                      value="No"
                      checked={pets.includes("No")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="no-pets">No</label>
                  </div>
                </div>
                <br />
                <p>Are you over the age of 18?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      id="over18-yes"
                      name="over18"
                      value="yes"
                      checked={over18 === "yes"}
                      onChange={(e) => setOver18(e.target.value)}
                    />
                    <label htmlFor="over18-yes">Yes</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="over18"
                      id="over18-no"
                      value="no"
                      checked={over18 === "no"}
                      onChange={(e) => setOver18(e.target.value)}
                    />
                    <label htmlFor="over18-no">No</label>
                  </div>
                </div>
                <br />
                <p>Where do you live?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="homeType"
                      id="hometype-apartment"
                      value="Apartment"
                      checked={homeType === "Apartment"}
                      onChange={(e) => setHomeType(e.target.value)}
                    />
                    <label htmlFor="hometype-apartment">Apartment</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="homeType"
                      value="House"
                      id="hometype-house"
                      checked={homeType === "House"}
                      onChange={(e) => setHomeType(e.target.value)}
                    />
                    <label htmlFor="hometype-house">House</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      id="hometype-other"
                      name="homeType"
                      value="other"
                      checked={homeType === "other"}
                      onChange={(e) => setHomeType(e.target.value)}
                    />
                    <label htmlFor="hometype-other">Other</label>
                  </div>
                </div>
                <br />
                <p>Are there any children living in your home?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="children"
                      value="yes"
                      id="children-yes"
                      checked={children === "yes"}
                      onChange={(e) => setChildren(e.target.value)}
                    />
                    <label htmlFor="children-yes">Yes</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="children"
                      id="children-no"
                      value="no"
                      checked={children === "no"}
                      onChange={(e) => setChildren(e.target.value)}
                    />
                    <label htmlFor="children-no">No</label>
                  </div>
                </div>
                <br />
                <p>Do you own or rent your home?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="homeOwnership"
                      value="own"
                      id="homeownership-own"
                      checked={homeOwnership === "own"}
                      onChange={(e) => setHomeOwnership(e.target.value)}
                    />
                    <label htmlFor="homeownership-own">Own</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="homeOwnership"
                      value="rent"
                      id="homeownership-rent"
                      checked={homeOwnership === "rent"}
                      onChange={(e) => setHomeOwnership(e.target.value)}
                    />
                    <label htmlFor="homeownership-rent">Rent</label>
                  </div>
                </div>
                <br />
                <p>
                  Are there any breeds that are not allowed in the household?
                </p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="forbiddenHouseholdBreeds"
                      value="yes"
                      id="forbiddenHouseholdBreeds-yes"
                      checked={forbiddenHouseholdBreeds === "yes"}
                      onChange={(e) =>
                        setForbiddenHouseholdBreeds(e.target.value)
                      }
                    />
                    <label htmlFor="forbiddenHouseholdBreeds-yes">Yes</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="forbiddenHouseholdBreeds"
                      value="no"
                      id="forbiddenHouseholdBreeds-no"
                      checked={forbiddenHouseholdBreeds === "no"}
                      onChange={(e) =>
                        setForbiddenHouseholdBreeds(e.target.value)
                      }
                    />
                    <label htmlFor="forbiddenHouseholdBreeds-no">No</label>
                  </div>
                </div>
                {forbiddenHouseholdBreeds === "yes" && (
                  <>
                    <br />
                    <Input
                      label={"What are the breeds that are not allowed?"}
                      value={forbiddenBreeds}
                      placeholder={""}
                      onChange={(e) => setForbiddenBreeds(e.target.value)}
                    />
                    <br />
                    <InputMask
                      mask="(999) 999-9999"
                      value={landlordNumber}
                      onChange={(e) => setLandlordNumber(e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          label={`We need to contact your landlord to confirm that ${dog.name}'s breed is allowed`}
                          {...inputProps}
                          placeholder="(440) 555-1234"
                          value={landlordNumber}
                        />
                      )}
                    </InputMask>
                    <br />
                  </>
                )}
              </div>
            </div>
          )}
          {selectedStep === 3 && (
            <>{isNewAccount ? <div className="adoption-form-scrollable-container">
            <div className="adoption-header">
              <span className="adoption-left-title">
                {"Application submitted! Now, finish creating your account."}
              </span>
            </div>
            <p className="adoption-left-description">
              {
                "Adding a password will let you access your application and communicate with our adoption team 24/7!"
              }
            </p>
            <br />
            
            <>
              <br />

              <Input
                label={"Email"}
                value={email}
                placeholder={"Enter your email"}
                onChange={handleEmailChange}
                required
                onBlur={handleEmailRequest}
                disabled
              />
            <br />

            
            <Input
                label='Password' 
                placeholder='Enter a password'
                type='password'
                onChange={(e) => { setPassword(e.target.value)}}
              />
            </>
            
            
          </div> : (<div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">
                  {"Application submitted!"}
                </span>
              </div>
              <p className="adoption-left-description">
                {
                  "Now, all that's left is a quick call with our team. Please confirm the phone number below is correct."
                }
              </p>
              <br />

              <div className="adoption-contact-container">
                <span className="adoption-mid-title">
                  The best number to reach you is:
                </span>
                <PhoneButton
                  phoneNumber={phone}
                  fn={() => setSelectedStep(1)}
                />
                <br />
                <br />
                
              </div>
              
              
            </div>)}</>
          )}
          {selectedStep === 2 && (
            <div className="adoption-form-scrollable-container">
              <div className="adoption-header">
                <span className="adoption-left-title">{"Application"}</span>
                <Counter max={max} current={current} />
              </div>
              <p className="adoption-left-description">
                {"We're assessing 'fit' for that dog, not overall fitness."}
              </p>
              <br />

              <div className="adoption-form-container">
                <Input
                  label={t("Why do you want do adopt?")}
                  value={motivation}
                  placeholder={"Tell us"}
                  onChange={(e) => setMotivation(e.target.value)}
                  required
                  textArea
                />
                <br />

                <Input
                  label={t(
                    "How much money (in $) do you anticipate spending per year on food, training, toys, vet bills, etc?"
                  )}
                  placeholder={t("Enter estimated spend")}
                  onChange={handleEstimatedSpendChange}
                  value={estimatedSpend}
                  required
                />

                <br />
                <p className="adoption-mid-title">Additional information</p>
                <p>Have you ever owned a dog before?</p>
                <div className="app-radio-options">
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="previousDog"
                      value="yes"
                      id="previousDog-yes"
                      checked={previousDog === "yes"}
                      onChange={(e) => setPreviousDog(e.target.value)}
                    />
                    <label htmlFor="previousDog-yes">Yes</label>
                  </div>
                  <div className="app-radio-option">
                    <input
                      type="radio"
                      name="previousDog"
                      value="no"
                      id="previousDog-no"
                      checked={previousDog === "no"}
                      onChange={(e) => setPreviousDog(e.target.value)}
                    />
                    <label htmlFor="previousDog-no">No</label>
                  </div>
                </div>
                <br />
                <p>Do you have any dealbreakers in terms of adopting a dog?</p>
                <div className="checkbox-grid">
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Excessive medical need")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Excessive medical need",
                          e.target.checked
                        )
                      }
                    />
                    Excessive medical need
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Resource guarding")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Resource guarding",
                          e.target.checked
                        )
                      }
                    />
                    Resource guarding
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Jumpy behavior")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Jumpy behavior",
                          e.target.checked
                        )
                      }
                    />
                    Jumpy behavior
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Excessive barking")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Excessive barking",
                          e.target.checked
                        )
                      }
                    />
                    Excessive barking
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Separation Anxiety")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Separation Anxiety",
                          e.target.checked
                        )
                      }
                    />
                    Separation Anxiety
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes(
                        "History of aggression towards dogs, cats, and/or children"
                      )}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "History of aggression towards dogs, cats, and/or children",
                          e.target.checked
                        )
                      }
                    />
                    History of aggression towards dogs, cats, and/or children
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Leash Puller")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Leash Puller",
                          e.target.checked
                        )
                      }
                    />
                    Leash Puller
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dealbreakers.includes("Not house trained")}
                      onChange={(e) =>
                        handleDealbreakerChange(
                          "Not house trained",
                          e.target.checked
                        )
                      }
                    />
                    Not house trained
                  </label>
                  {/* Add more checkboxes and labels as needed */}
                </div>
              </div>
            </div>
          )}
          {selectedStep < 3 ? (
            <div className="adoption-button-wrapper">
              <Button
                title={"Back"}
                onClick={() => {
                  if (selectedStep === 1) navigate("/profile?tab=3");
                  else setSelectedStep((previous) => previous - 1);
                }}
              />
              <Button
                title={selectedStep === 2 ? "Send" : t("user.next")}
                filled
                onClick={() => {
                  if (selectedStep === 2) {
                    handleAdoptionUpdate(true, false);
                  }
                  handleNext(0);
                }}
              />
            </div>
          ) : (
            <div className="adoption-button-wrapper">
              <Button
                title={"More dogs"}
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/discovery');
                }}
              />
              <Button
                title={"Complete"}
                filled
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/profile?tab=3');
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="right-adoption">
        {selectedStep === 3 ? (
          <div className="adoption-summary">
            {dog && (
              <img alt='PetPair' src={dog.photos[0]} className="adoption-img-summary" />
            )}
            <div className="ribbon">
              <span className="ribbon-text">complete!</span>
            </div>
            <div className="adoption-summary-info">
              <span className="adoption-summary-title">{name ? `${name}'s application for ${dog.name}` : `Application for ${dog.name}`}</span>
            </div>
            <div className="adoption-summary-adopter-info">
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Name</span>
                <span className="adoption-summary-value">{name}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Phone number</span>
                <span className="adoption-summary-value">{phone}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Location</span>
                <span className="adoption-summary-value">{location}</span>
              </div>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Why do you want to adopt?
              </span>
              <span className="adoption-summary-value">{motivation}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                How much money (in $) do you anticipate spending per year on
                food, training, toys, vet bills, etc?
              </span>
              <span className="adoption-summary-value">{estimatedSpend}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Do you have any pets at home?
              </span>
              <span className="adoption-summary-value">{pets.join(", ")}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Are you over the age of 18?
              </span>
              <span className="adoption-summary-value">{over18}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">Where do you live?</span>
              <span className="adoption-summary-value">{homeType}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Are there any children living in your home?
              </span>
              <span className="adoption-summary-value">{children}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Do you own or rent your home?
              </span>
              <span className="adoption-summary-value">{homeOwnership}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Are there any breeds that are not allowed in the household?
              </span>
              <span className="adoption-summary-value">
                {forbiddenHouseholdBreeds}
              </span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Have you ever owned a dog before?
              </span>
              <span className="adoption-summary-value">{previousDog}</span>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Do you have any dealbreakers in terms of adopting a dog?
              </span>
              <span className="adoption-summary-value">
                {dealbreakers.join(", ")}
              </span>
            </div>
          </div>
        ) : (
          <>
            {dog && (
              <div className="adoption-dog-container">
                <div className="adoption-dog-info">
                  <span className="adoption-name">{dog.name}</span>
                  <span className="adoption-more-info">
                    {(dog.age > 0 && dog.age <= 1
                      ? dog.age + " year old"
                      : dog.age + " years old") +
                      " | " +
                      dog.weight +
                      " lbs."}
                  </span>
                  <img alt='PetPair' src={dog.photos[0]} className="adoption-info-avatar" />
                </div>
              </div>
            )}
            <HeartFixedCta />
            <div className="adoption-dog-container">
              <div className="adoption-dog-info">
                <span className="adoption-name">{"You"}</span>
                <span className="adoption-more-info">{"Dog parent"}</span>
                <img alt='PetPair' src={defaultAvatar} className="adoption-info-avatar" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdoptionApp;

import React, { useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import DogCarousel from "../../components/DogCarousel";
import DogCard from "../../components/DogCard";
import Spinner from "../../components/Spinner";
import BreadcrumbArrow from "../../assets/svg/BreadcrumbArrow";
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import { Divider } from "antd";
import "./discovery.css";
import StarIcon from "../../assets/svg/StarIcon";
import Footer from "../../components/Footer";
import {
  getDogDimensions,
  getDogs,
  getUserLocation,
  getUsers,
} from "../../service/actions";
import Dropdown from "../../components/Dropdown";
import DogHeart from "../../assets/svg/DogHeart";
import Location from "../../assets/svg/Location";
import { age, weight } from "../../utils";
import { useTranslation } from "react-i18next";
import DogIcon from "../../assets/svg/DogIcon";
import FilterIcon from "../../assets/svg/FilterIcon";
import FilterTag from "../../components/FilterTag";
import { Helmet } from 'react-helmet-async';


const DogDiscovery = () => {
  const [dogs, setDogs] = useState<any>([]);
  const { t} = useTranslation();
  const [selectedTraits, setSelectedTraits] = useState<any>([]);
  const [selectedAge, setSelectedAge] = useState<any>([]);
  const [selectedWeight, setSelectedWeight] = useState<any>([]);
  const [selectedSort, setSelectedSort] = useState("distance");
  const [filters, setFilters] = useState<any>({ traits: [] });
  const [token, setToken] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  const location = useLocation();

  const dogsAction = async (
    zipCode: string,
    sort: string,
    traits: any,
    age: any,
    weight: any
  ) => {
    const request = await getDogs(traits, zipCode, [], sort, age, weight);
    setDogs(request.dogs);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const getLocation = async () => {
      try {
        const location = await getUserLocation();
        setZipCode(location.country === "Brazil" ? "94541" : location.zip);
      } catch (error) {
        console.error(error);
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    let ageFilter: any = [];
    let weightFilter: any = [];
    let user, accessToken: string;

    try {
      user = JSON.parse(localStorage.getItem("user") as any);
      accessToken = JSON.parse(localStorage.getItem("token") as any);
      setToken(token);
    } catch {
      console.log("Cache not available");
    }

    datadogLogs.logger.info("Dog discovery page loaded", {
      user,
      url: window.location.href,
    });

    age.map((age: any) => ageFilter.push({ label: age, value: age }));
    weight.map((weight: any) =>
      weightFilter.push({ label: weight, value: weight })
    );

    const dogDimensions = async () => {
      let traits: any = [];
      const request = await getDogDimensions();
      request.traits.map((trait: any) =>
        traits.push({ label: trait.name, value: trait.id })
      );
      setFilters({ ...filters, traits, age: ageFilter, weight: weightFilter });
    };

    const usersList = async () => {
      let traits: any = [];
      await getUsers(accessToken);
      setFilters({ ...filters, traits, age: ageFilter, weight: weightFilter });
    };

    usersList();

    dogDimensions();
    const queryParams = new URLSearchParams(location.search);
    const traitsParam = queryParams.get("traits");
    const ageParam = queryParams.get("age");
    const weightParam = queryParams.get("weight");
    let selectedTraitsArr: any = [];
    let selectedAgeArr: any = [];
    let selectedWeightArr: any = [];

    if (traitsParam) {
      selectedTraitsArr = traitsParam.split(",");
      setSelectedTraits(selectedTraitsArr);
    }

    if (ageParam) {
      selectedAgeArr = ageParam.split(",");
      setSelectedAge(selectedAgeArr);
    }

    if (weightParam) {
      selectedWeightArr = weightParam.split(",");
      setSelectedWeight(selectedWeightArr);
    }

    dogsAction(
      user?.zipCode || "",
      "name",
      selectedTraitsArr,
      selectedAgeArr,
      selectedWeightArr
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let sortBy = selectedSort === "distance" ? "name" : selectedSort;

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("traits", selectedTraits.join(","));
    queryParams.set("age", selectedAge.join(","));
    queryParams.set("weight", selectedWeight.join(","));

    const queryString = queryParams.toString();

    // Update URL query parameters without navigating
    window.history.replaceState(null, "", `?${queryString}`);

    dogsAction(
      (selectedSort === "distance" && zipCode) || "",
      sortBy,
      selectedTraits,
      selectedAge,
      selectedWeight
    );
  }, [selectedTraits, selectedSort, zipCode, selectedAge, selectedWeight, location.search]);

  const toggleMenu = () => {
    setMenuExpanded(!isMenuExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1366 && isMenuExpanded) {
        setMenuExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuExpanded]);

  if (loading) {
    return (
      <div>
        <div className="discovery-wrapper">
          <NavBar />
          <Spinner isDone={false} page={true} />
        </div>
      </div>
    );
  }

  const handleRemove = (type: any, value: any) => {
    if (type === "traits")
      setSelectedTraits((prevState: any) =>
        prevState.filter((trait: any) => trait !== value)
      );
    if (type === "age")
      setSelectedAge((prevState: any) =>
        prevState.filter((age: any) => age !== value)
      );
    if (type === "weight")
      setSelectedWeight((prevState: any) =>
        prevState.filter((weight: any) => weight !== value)
      );
  };

  return (
    <div>
      <Helmet>
  <title>{`PetPair | Apply Today for Local Dogs Available for Adoption!`}</title>
  <meta name="description" content={`Our fosters have 100% adoptable dogs ready for you to meet. Meet the dogs in-person or virtually, apply on our site, and take them home today!`} />
</Helmet>

      <div className="discovery-wrapper">
        <NavBar />
        <div className="discovery-root">
          <div className="discovery-header">
            <div className="discovery-breadcrumbs">
              <span className="faded-text">Home</span>
              <div className="bc-arrow">
                <BreadcrumbArrow />
              </div>
              <span className="normal-text">Available dogs</span>
            </div>
            <div className="content">
              <span className="title">
                {selectedTraits.length > 0 ||
                selectedWeight.length > 0 ||
                selectedAge.length > 0
                  ? dogs.length +
                    (dogs.length === 1
                      ? t("dogDiscovery.availableDogsFilter")
                      : t("dogDiscovery.availableDogsFilterPlural"))
                  : t("dogDiscovery.availableDogs")}
              </span>
              {window.innerWidth <= 1366 && (
                <div
                  className={`toggle-menu ${isMenuExpanded ? "expanded" : ""}`}
                  onClick={toggleMenu}
                >
                  <FilterIcon />
                </div>
              )}
              <div
                className={`discovery-filters ${
                  isMenuExpanded ? "expanded" : ""
                }`}
              >
                <Dropdown
                  callback={setSelectedTraits}
                  placeholder="Traits"
                  options={filters.traits}
                  Icon={DogHeart}
                  selected={selectedTraits}
                />
                <Dropdown
                  placeholder="Age"
                  options={filters.age}
                  Icon={DogIcon}
                  callback={setSelectedAge}
                  selected={selectedAge}
                />
                <Dropdown
                  placeholder="Size"
                  options={filters.weight}
                  Icon={DogIcon}
                  callback={setSelectedWeight}
                  selected={selectedWeight}
                />
                <Dropdown
                  singleOption
                  callback={setSelectedSort}
                  placeholder={"Sort by " + selectedSort}
                  options={[
                    { label: "Sort by distance", value: "distance" },
                    { label: "Sort by age", value: "age" },
                    { label: "Sort by size", value: "weight" },
                  ]}
                  Icon={Location}
                  selected={[]}
                  selectedSingle={selectedSort}
                />
              </div>
            </div>
            <div className="selected-filters">
              {selectedTraits.map((trait: any) => (
                <FilterTag
                  title={filters.traits[trait - 1].label}
                  remove={() =>
                    handleRemove("traits", filters.traits[trait - 1].value)
                  }
                />
              ))}
              {selectedAge.map((age: any) => (
                <FilterTag
                  title={
                    filters.age.filter(
                      (filterAge: any) => filterAge.label === age
                    )[0].label
                  }
                  remove={() =>
                    handleRemove(
                      "age",
                      filters.age.filter(
                        (filterAge: any) => filterAge.label === age
                      )[0].value
                    )
                  }
                />
              ))}
              {selectedWeight.map((weight: any) => (
                <FilterTag
                  title={
                    filters.weight.filter(
                      (filterWeight: any) => filterWeight.label === weight
                    )[0].label
                  }
                  remove={() =>
                    handleRemove(
                      "weight",
                      filters.weight.filter(
                        (filterWeight: any) => filterWeight.label === weight
                      )[0].value
                    )
                  }
                />
              ))}
            </div>
            <Divider />
            {selectedTraits.length === 0 &&
              selectedWeight.length === 0 &&
              selectedAge.length === 0 && (
                <>
                  <div>
                    <StarIcon />
                    <span className="discovery-section-title">
                      {t("dogDiscovery.topDogs")}
                    </span>
                  </div>
                  <span className="discovery-section-title2">
                    {t("dogDiscovery.topDogsDescription")}
                  </span>
                  <DogCarousel>
                    {dogs.map((dog: any, index: any) => (
                      <DogCard
                        slug={dog.slug}
                        key={dog.slug + dog.name + dog.index}
                        name={dog.name}
                        age={dog.age}
                        weight={dog.weight}
                        breed={dog.breed.name}
                        img={(dog.photos[0] || '')}
                        location={"/dog/" + dog.slug}
                        id={dog.id}
                      />
                    ))}
                  </DogCarousel>
                  <Divider />
                </>
              )}
            {selectedTraits.length === 0 &&
              selectedWeight.length === 0 &&
              selectedAge.length === 0 && (
                <>
                  <div>
                    <span className="discovery-section-title-all">
                      {t("dogDiscovery.allDogs")}
                    </span>
                  </div>
                  <span className="discovery-section-title2">
                    {t("dogDiscovery.allDogsDescription")}
                  </span>
                </>
              )}
            <div className="discovery-all-dogs">
              {dogs.map((dog: any, index: any) => (
                <DogCard
                  slug={dog.slug}
                  allDogs
                  key={dog.slug + dog.name + dog.index}
                  name={dog.name}
                  age={dog.age}
                  weight={dog.weight}
                  breed={dog.breed.name}
                  img={(dog.photos[0] || '')}
                  location={"/dog/" + dog.slug}
                  id={dog.id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DogDiscovery;

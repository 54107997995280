import React, { useState, useEffect, useRef } from "react";
import DropdownDownArrow from "../../assets/svg/DropdownDownArrow";
import DropdownUpArrow from "../../assets/svg/DropdownUpArrow";
import "./dropdown.css";

const Dropdown: React.FC<{
  Icon?: React.FC;
  options?: any[];
  placeholder?: string;
  callback?: Function;
  singleOption?: boolean;
  selected: any[];
  selectedSingle?: string;
}> = ({
  Icon,
  options,
  placeholder,
  callback,
  singleOption,
  selected,
  selectedSingle,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (
    event: React.MouseEvent<HTMLLIElement>,
    option: any
  ) => {
    event.stopPropagation();
    if (singleOption && callback) {
      callback(option);
      return false;
    }
    if (!selected.includes(option)) {
      if (callback) {
        callback([...selected, option]);
      }
    } else {
      if (callback)
        (callback as any)(
          selected.filter((element: any) => element !== option)
        );
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={`dropdown ${isOpen && "dropdown-open"}`}
      ref={dropdownRef}
      onClick={toggleDropdown}
    >
      <div className="dropdown-placeholder">
        {Icon && <Icon />}
        <span className="dropdown-title">{placeholder}</span>
      </div>
      <div className="dropdown-open-indicator">
        {isOpen ? <DropdownUpArrow /> : <DropdownDownArrow />}
      </div>
      {isOpen && (
        <ul className="dropdown-options fade-in">
          {options &&
            options.map((option: any, index: number) => (
              <li
                key={index}
                onClick={(e) => handleOptionClick(e, option.value as number)}
              >
                <input
                  className="dropdown-checkbox"
                  type="checkbox"
                  checked={
                    singleOption
                      ? selectedSingle === option.value
                      : selected.includes(option.value)
                  }
                />
                <span className="dropdown-label">{option.label}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

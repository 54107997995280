const MobileShareIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59 13.01L13.42 16.99M13.41 6.01L6.59 9.99M19 4.5C19 6.15685 17.6569 7.5 16 7.5C14.3431 7.5 13 6.15685 13 4.5C13 2.84315 14.3431 1.5 16 1.5C17.6569 1.5 19 2.84315 19 4.5ZM7 11.5C7 13.1569 5.65685 14.5 4 14.5C2.34315 14.5 1 13.1569 1 11.5C1 9.84315 2.34315 8.5 4 8.5C5.65685 8.5 7 9.84315 7 11.5ZM19 18.5C19 20.1569 17.6569 21.5 16 21.5C14.3431 21.5 13 20.1569 13 18.5C13 16.8431 14.3431 15.5 16 15.5C17.6569 15.5 19 16.8431 19 18.5Z"
      stroke="#53B62D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MobileShareIcon;

import React from "react";

const Location = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00033 7.00016C6.73366 7.00016 7.33366 6.40016 7.33366 5.66683C7.33366 4.9335 6.73366 4.3335 6.00033 4.3335C5.26699 4.3335 4.66699 4.9335 4.66699 5.66683C4.66699 6.40016 5.26699 7.00016 6.00033 7.00016ZM6.00033 0.333496C8.80033 0.333496 11.3337 2.48016 11.3337 5.80016C11.3337 7.92016 9.70033 10.4135 6.44033 13.2868C6.18699 13.5068 5.80699 13.5068 5.55366 13.2868C2.30033 10.4135 0.666992 7.92016 0.666992 5.80016C0.666992 2.48016 3.20033 0.333496 6.00033 0.333496Z"
      fill="black"
    />
  </svg>
);

export default Location;
